import { useState, Fragment, useMemo } from "react";

import { Link, useLocation } from "react-router-dom";

import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import TermConditionModal from "./termsConPrivacyModals/TermConditionModal";
import PrivacyModal from "./termsConPrivacyModals/PrivacyModal";

import logo from "../assets/images/logo.svg";
import approveImage from "../assets/images/Approve.png";
import mailImage from "../assets/images/mailmessage.png";
import instaImage from "../assets/images/instagram.png";
import telephoneImage from "../assets/images/telephone.png";
import twitterImage from "../assets/images/twitter.png";

const FooterDash = () => {
  const { user } = useUserState();
  const dispatch = useUserDispatch();
  const [termConModal, setTermConModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  const location = useLocation();

  const otherRoles = user?.role?.filter((r) => r !== user?.currentRole);
  const content = useMemo(() => {
    if (
      location.pathname === "/user_dash/relo" ||
      location.pathname === "/user_dash/tenant"
    ) {
      return `
        <ul style=" text-align: left;padding: 20px 10px 0 10px;">
          <p style="margin-top: 0px;">DRUYP!</p>
          <p style="margin-top: 0px;">Life is too short,</p>
          <p style="margin-top: 0px;">Live your life on your own terms,</p>
          <p style="margin-top: 0px;">Customize your booking.</p>
</ul>        
      `;
    } else if (
      location.pathname === "/user_dash/relo/favorite-houses" ||
      location.pathname === "/user_dash/tenant/favorite-houses" ||
      location.pathname === "/user_dash/relo/history" ||
      location.pathname === "/user_dash/tenant/history" ||
      location.pathname === "/user_dash/relo/current-plan" ||
      location.pathname === "/user_dash/tenant/current-plan"
    ) {
      return `
        <ul style=" text-align: left;padding: 20px 15px 0 10px;">
          <p>Your one-stop shop for customized & Furnished short term rental. For customized option, you pick a property of your choice, you customize the furnishing with the furniture package of your choice and pick the cleaning package of your choice. For furnished option, you pick your desired property of your choice, and pick the cleaning package of your choice.</p>
        </ul>
      `;
    } else if (
      location.pathname === "/user_dash/tenant/negotiations" ||
      location.pathname === "/user_dash/relo/negotiations"
    ) {
      return `
   <ul style=" text-align: left;padding: 20px 15px 0 10px;">
          <p>With our unique negotiation feature, you can send personalized offers, counter proposals, and reach a mutually beneficial agreement right within the platform. Take control of your purchasing power and unlock the best value for your needs.</p>
        </ul>
      `;
    } else if (
      location.pathname === "/user_dash/tenant/billing" ||
      location.pathname === "/user_dash/relo/billing"
    ) {
      return `
   <ul style=" text-align: left;padding: 20px 15px 0 10px;">
          <p>Your sensitive information is safe with us. We utilize a robust, secure platform to protect your personal details, ensuring complete privacy throughout your billing process.</p>
        </ul>
      `;
    } else if (
      location.pathname === "/user_dash/tenant/login_security" ||
      location.pathname === "/user_dash/relo/login_security"
    ) {
      return `
   <ul style=" text-align: left;padding: 20px 15px 0 10px;">
          <p>Your information is protected by our robust security system, ensuring your profile remains safe from unauthorized access. We utilize strong encryption, multifactor authentication, and constant monitoring to safeguard your data, giving your peace of mind when logging in to our platform.</p>
        </ul>
      `;
    } else if (
      location.pathname === "/user_dash/tenant/notifications" ||
      location.pathname === "/user_dash/relo/notifications"
    ) {
      return `
        <ul style=" text-align: left;padding: 20px 15px 0 10px; font-size: 12px;">
          <p>We offer detailed notifications to keep you informed about: booking updates, status, account activity, new features, exclusive deals, upcoming events, and personalized recommendations - ensuring you never miss an update.</p>
        </ul>
      `;
    }
    return ""; // Return an empty string if no match
  }, [location.pathname]);

  return (
    <Fragment>
      <footer>
        <section className="foot_sec">
          <div className="container-fluid">
            <div className="foot_sec_inner">
              <div className="foot_cont footer-box">
                <div className="foot_logo content_footer">
                  <Link to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: content, // Inject the HTML string as content
                  }}
                />
              </div>
              <div className="foot_cont">
                <div className="foot_head">Druyp</div>
                <ul>
                  <li>
                    <Link to="/">
                      <img src={approveImage} alt="" /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/properties">
                      <img src={approveImage} alt="" /> Get a Choice
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="">
                      <img src={approveImage} alt="" /> Download
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/contact-us">
                      <img src={approveImage} alt="" /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              {user &&
                user?.role?.length <= 3 &&
                user?.role?.length > 1 &&
                !user?.role?.includes("furniture_company") &&
                !user?.role?.includes("cleaning_company") &&
                !user?.role?.includes("relo") && (
                  <div className="foot_cont">
                    <div className="foot_head">Host</div>
                    <ul>
                      {otherRoles.map((r, index) => (
                        <li key={index}>
                          <Link
                            to={
                              r === "tenant"
                                ? "/user_dash/tenant"
                                : r === "home_owner"
                                ? "/user_dash/home_owner"
                                : r === "relo"
                                ? "/user_dash/relo"
                                : r === "property_manager"
                                ? "/user_dash/property_manager"
                                : ""
                            }
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                behavior: "instant",
                              });
                              const updatedUserRole = {
                                ...user,
                                currentRole: r,
                              };
                              // Dispatch an action to update the user in the context
                              dispatch({
                                type: "UPDATE_USER",
                                payload: updatedUserRole,
                              });
                            }}
                          >
                            <img src={approveImage} />{" "}
                            {r === "tenant"
                              ? "Tenant"
                              : r === "relo"
                              ? "Relocation Agent"
                              : r === "home_owner"
                              ? "Home Owner"
                              : r === "property_manager"
                              ? "Property Manager"
                              : ""}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <div className="foot_cont">
                <div className="foot_head">Other Links</div>
                <ul>
                  <li>
                    <Link to="/about-us">
                      <img src={approveImage} alt="" /> About Us
                    </Link>
                  </li>
                  <li>
                    <button onClick={() => setTermConModal(true)}>
                      <img src={approveImage} alt="" /> Terms & Conditions
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setPrivacyModal(true)}>
                      <img src={approveImage} alt="" /> Privacy Policy
                    </button>
                  </li>
                </ul>
              </div>
              <div className="foot_cont">
                <div className="foot_head">Contact us</div>
                <ul>
                  <li>
                    <a href="#">
                      <img src={telephoneImage} alt="" /> (217) 443-4545
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={mailImage} alt="" /> info@druyp.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={instaImage} alt="" /> @druyp_insta
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <img src={twitterImage} alt="" /> @druyp_twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="m-0" />
          <div className="foot_copyright">
            <p>©{new Date().getFullYear()} All rights reserved by Druyp</p>
          </div>
        </section>
      </footer>
      <TermConditionModal
        termConModal={termConModal}
        setTermConModal={setTermConModal}
      />
      <PrivacyModal
        privacyModal={privacyModal}
        setPrivacyModal={setPrivacyModal}
      />
    </Fragment>
  );
};

export default FooterDash;
