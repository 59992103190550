import "./Negotiations.css";
import { useUserState } from "../../Contexts/UserContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import UpIcon from "../../assets/images/up.png";
import DownIcon from "../../assets/images/down.png";
import HouseDummyImg from "../../assets/images/houseDummy.webp";


const Negotiations = () => {
  const navigate = useNavigate();
  const { user } = useUserState();
  const [isLoader, setIsLoader] = useState(true);
  const [showDetail, setShowDetail] = useState({
    id: "",
    condition: false,
  });
  const [negoList, SetNegoList] = useState([]);
  
  const getNegotiations = async () => {
    // let userToken = localStorage.getItem("user");
    const headerJson = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };

    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/house-price-negotiate/get-list/${user?.currentRole}`,
        {
          headers: headerJson,
        }
      );
      setIsLoader(false);
      SetNegoList(response.data.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (user) {
      getNegotiations();
    }
  }, [user]);

  return (
    <div className="main-box">
      <div className="content-header">
        <h3>Negotiations</h3>
      </div>
      <div className="row">
        <div className="col-12">
          {negoList?.map((ele, index) => {
            return (
              <div key={index}>
                <div className="hist_main">
                  <div className="hist_img">
                    <img
                      style={{ height: "100px", width: "100px" }}
                      src={
                        ele.relatedHouse.houseImages.length > 0
                          ? `${import.meta.env.VITE_APP_BASE_URL}${
                              ele.relatedHouse.houseImages[0].imageUrl
                            }`
                          : histhomeimg
                      }
                      alt="histhomeimg"
                      onError={(e) => {
                        e.target.src = HouseDummyImg;
                      }}
                    />
                  </div>
                  <div className="hist_cont">
                    <div className="acc">
                      <h4>{ele.relatedHouse.name}</h4>
                      <div className="dir_btn my-new">
                        <div className="show-details">
                          {ele.status == "in_progress" ? (
                            <span className="dot_prog">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <circle cx="5" cy="5" r="5" fill="#FF5C16" />
                              </svg>{" "}
                              In Progress
                            </span>
                          ) : ele.status === "tenant_accept" ||
                            ele.status === "owner_accept" ? (
                            <span className="dot_acc">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <circle cx="5" cy="5" r="5" fill="#009900" />
                              </svg>{" "}
                              Accepted
                            </span>
                          ) : ele.status === "tenant_reject" ||
                            ele.status === "owner_reject" ? (
                            <span className="dot_rej">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <circle cx="5" cy="5" r="5" fill="#F71010" />
                              </svg>{" "}
                              Rejected
                            </span>
                          ) : (
                            <span className="dot_exp">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <circle cx="5" cy="5" r="5" fill="#B4B4B4" />
                              </svg>{" "}
                              Expired
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            navigate(`/property_detail/${ele.house_id}`);
                          }}
                          type="button"
                        >
                          View Property
                        </button>
                        <span
                          className="details-sec"
                          onClick={() =>
                            setShowDetail((prev) => ({
                              id: ele?.id,
                              condition:
                                prev.id === ele?.id && prev.condition
                                  ? false
                                  : true,
                            }))
                          }
                        >
                          {showDetail.id === ele?.id &&
                          showDetail?.condition ? (
                            <>
                              Hide Details
                              <img src={UpIcon} alt="detailIcon" />
                            </>
                          ) : (
                            <>
                              Show Details
                              <img src={DownIcon} alt="detailIcon" />
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <p>
                      {ele.relatedHouse.full_address?.split(";")[0]},{" "}
                      {ele.relatedHouse.full_address?.split(";")[1]},{" "}
                      {ele.relatedHouse.full_address?.split(";")[2]}
                    </p>
                    <div className="month_cont">
                      <h2>
                        From
                        <span>
                          {new Date(
                            ele.lease_start_date
                              ? ele.lease_start_date
                              : ele?.negotiationData[0]
                                  ?.cust_offered_lease_start_date
                          ).toDateString()}
                        </span>
                      </h2>
                      <h2>
                        To
                        <span>
                          {new Date(
                            ele.lease_end_date
                              ? ele.lease_end_date
                              : ele?.negotiationData[0]
                                  ?.cust_offered_lease_end_date
                          ).toDateString()}
                        </span>
                      </h2>
                    </div>

                    {showDetail.id === ele?.id && showDetail?.condition && (
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Round</th>
                            <th scope="col">Tenant</th>
                            <th scope="col">Owner</th>
                          </tr>
                        </thead>

                        <tbody>
                          {ele?.negotiationData?.map((element, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>${element.cust_offered_price}/month </td>
                                <td>
                                  {ele?.status === "owner_accept" ? (
                                    `$${element?.cust_offered_price}`
                                  ) : element?.owner_offered_price == null ? (
                                    <span className="text-warning">
                                      Pending
                                    </span>
                                  ) : element?.owner_offered_price ? (
                                    <span>
                                      ${element?.owner_offered_price}/month
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <hr className="m-0" />
              </div>
            );
          })}

          {isLoader && (
           <div className="eye-loader-frame m-5 p-5">
           <div className="eye-loader-spinner"></div>
         </div>
          )}

          {negoList.length == 0 && !isLoader && (
            <div className="loading-data">There is no Negotiations.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Negotiations;
