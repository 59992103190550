import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";

import { useUserAuth } from "./UserAuthContext";
import openEye from "../assets/icons/open-eye.svg";
import closeEye from "../assets/icons/closed-eye.svg";
import mail from "../assets/icons/mail.svg";
import person from "../assets/icons/person.svg";
import facebookIcon from "../assets/icons/Facebook.svg";
import googleIcon from "../assets/icons/Google.svg";
import appleIcon from "../assets/icons/Apple.svg";
import PWD from "../assets/icons/password.svg";
import phone from "../assets/icons/phone.svg";

import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import Button from "./common/LoadingButton";

const Signup = () => {
  const navigate = useNavigate();
  const { facebookSignIn, googleSignIn, appleSignIn, user } = useUserAuth();
  const [socialType, setSocialType] = useState("");
  const [inputData, setInputData] = useState({
    roles: [],
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    pwd: "",
    conPwd: "",
    agreementCheck: false,
  });
  const [formError, setFormError] = useState(false);
  const [pwdEye, setPwdEye] = useState(false);
  const [conPwdEye, setConPwdEye] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [signUpError, setSignUpError] = useState("");

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const pass = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  const handleFacebookLogin = async (e) => {
    e.preventDefault();
    try {
      await facebookSignIn();
      setSocialType("FaceLogin");
      setInputData({
        first_name: user?.displayName?.split(" ")[0],
        last_name: user?.displayName?.split(" ")?.slice(1)?.join(" "),
        email: user?.email,
        roles: [],
        phone_number: "",
        pwd: "",
        conPwd: "",
        agreementCheck: false,
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      setSocialType("GoogleLogin");
      setInputData({
        first_name: user?.displayName?.split(" ")[0],
        last_name: user?.displayName?.split(" ")?.slice(1)?.join(" "),
        email: user?.email,
        roles: [],
        phone_number: "",
        pwd: "",
        conPwd: "",
        agreementCheck: false,
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleAppleSignIn = async (e) => {
    e.preventDefault();
    try {
      await appleSignIn();
      window.localStorage.setItem("user", 123);
      navigate("/Sign_up");
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckboxChange = (value) => {
    if (inputData?.roles?.includes(value)) {
      setInputData({
        ...inputData,
        roles: inputData?.roles?.filter((item) => item !== value),
      });
    } else {
      setInputData({...inputData, roles: [...inputData?.roles, value] });
    }
  };

  const handleSubmit = async () => {
    const {
      roles,
      first_name,
      last_name,
      phone_number,
      email,
      pwd,
      conPwd,
      agreementCheck,
    } = inputData;

    if (
      roles?.length <= 0 ||
      !first_name ||
      !phone_number ||
      phone_number?.length < 10 ||
      !email ||
      !emailRegex.test(email) ||
      !pwd ||
      !pass.test(pwd) ||
      !conPwd ||
      pwd !== conPwd ||
      !agreementCheck
    ) {
      setFormError(true);
    } else {
      setFormError(false);
      try {
        setBtnLoader(true);
        await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/register`,
          {
            email: email,
            password: pwd,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            role: roles,
            social_login: "",
          }
        );

        navigate("/verify_email");
        setBtnLoader(false);
      } catch (error) {
        console.log("Error while hitting sign up API", error);
        setBtnLoader(false);
        setSignUpError(error?.response?.data?.message);
      }
    }
  };


  return (
    <div className="druyp_sigup">
      <section className="druyp_login_sec position-relative">
        <div className="container-fluid">
          <div className="row align-items-center min-vh-100">
            <div className="col-lg-6">
              <div className="druyp_login_inner">
                <div className="druyp_logo ">Druyp</div>
                <h4>Select roles</h4>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-check druyp_check sign_check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="tenant"
                            value="tenant"
                            checked={inputData?.roles?.includes("tenant")}
                            onChange={() => handleCheckboxChange("tenant")}
                            disabled={inputData?.roles?.some((element) =>
                              [
                                "relo",
                                "furniture_company",
                                "cleaning_company",
                              ]?.includes(element)
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tenant"
                            style={{ fontWeight: "normal" }}
                          >
                            Tenant
                          </label>
                        </div>
                        <div className="form-check druyp_check sign_check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="home_owner"
                            value="home_owner"
                            checked={inputData?.roles?.includes("home_owner")}
                            onChange={() => handleCheckboxChange("home_owner")}
                            disabled={inputData?.roles?.some((element) =>
                              [
                                "relo",
                                "furniture_company",
                                "cleaning_company",
                              ]?.includes(element)
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="home_owner"
                            style={{ fontWeight: "normal" }}
                          >
                            Home Owner
                          </label>
                        </div>
                        <div className="form-check druyp_check sign_check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="property_manager"
                            value="property_manager"
                            checked={inputData?.roles?.includes(
                              "property_manager"
                            )}
                            onChange={() =>
                              handleCheckboxChange("property_manager")
                            }
                            disabled={inputData?.roles?.some((element) =>
                              [
                                "relo",
                                "furniture_company",
                                "cleaning_company",
                              ]?.includes(element)
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="property_manager"
                          >
                            Property Manager
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-check druyp_check sign_check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="furniture_company"
                            value="furniture_company"
                            checked={inputData?.roles?.includes(
                              "furniture_company"
                            )}
                            onChange={() =>
                              handleCheckboxChange("furniture_company")
                            }
                            disabled={inputData?.roles?.some((element) =>
                              [
                                "tenant",
                                "home_owner",
                                "property_manager",
                                "cleaning_company",
                                "relo",
                              ]?.includes(element)
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="furniture_company"
                            style={{ fontWeight: "normal" }}
                          >
                            Furniture Company
                          </label>
                        </div>
                        <div className="form-check druyp_check sign_check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="cleaning_company"
                            value="cleaning_company"
                            checked={inputData?.roles?.includes(
                              "cleaning_company"
                            )}
                            onChange={() =>
                              handleCheckboxChange("cleaning_company")
                            }
                            disabled={inputData?.roles?.some((element) =>
                              [
                                "tenant",
                                "home_owner",
                                "property_manager",
                                "furniture_company",
                                "relo",
                              ]?.includes(element)
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="cleaning_company"
                            style={{ fontWeight: "normal" }}
                          >
                            Cleaning Company
                          </label>
                        </div>

                        <div className="form-check druyp_check sign_check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="relo"
                            value="relo"
                            checked={inputData?.roles?.includes("relo")}
                            onChange={() => handleCheckboxChange("relo")}
                            disabled={inputData?.roles?.some((element) =>
                              [
                                "tenant",
                                "home_owner",
                                "property_manager",
                                "furniture_company",
                                "cleaning_company",
                              ]?.includes(element)
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="relo"
                            style={{ fontWeight: "normal" }}
                          >
                            Relo
                          </label>
                        </div>
                      </div>
                      {formError && inputData?.roles?.length <= 0 && (
                        <div className="sap-val-error text-danger mb-0 mt-1">
                          Please select atleast one role
                        </div>
                      )}
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-6 my-2">
                            {socialType && user?.displayName ? (
                              ""
                            ) : (
                              <>
                                <div className="inp_field">
                                  <label
                                    htmlFor="firstName"
                                    className="form-label font-weight-bold"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    id="firstName"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your first name"
                                    value={inputData?.first_name}
                                    onKeyDown={(e) => {
                                      if (e.key === " ") {
                                        e.preventDefault();
                                      }
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) =>
                                      setInputData({
                                        ...inputData,
                                        first_name: e.target.value,
                                      })
                                    }
                                  />
                                  <div className="person_icon">
                                    <img src={person} alt="person image" />
                                  </div>
                                </div>

                                {formError && !inputData?.first_name && (
                                  <div className="sap-val-error text-danger mb-0 mt-1">
                                    Please enter your first name
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          {socialType && user?.displayName ? (
                            ""
                          ) : (
                            <div className="col-lg-6 my-2">
                              <div className="inp_field">
                                <label
                                  htmlFor="lastName"
                                  className="form-label font-weight-bold"
                                >
                                  Last Name
                                </label>
                                <input
                                  id="lastName"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your last name"
                                  value={inputData?.last_name}
                                  onKeyPress={(e) => {
                                    if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) =>
                                    setInputData({
                                      ...inputData,
                                      last_name: e.target.value,
                                    })
                                  }
                                />
                                <div className="person_icon">
                                  <img src={person} alt="person image" />
                                </div>
                              </div>
                              {formError && !inputData?.last_name && (
                                  <div className="sap-val-error text-danger mb-0 mt-1">
                                    Please enter your last name
                                  </div>
                                )}
                              
                           
                            </div>
                          )}

                          {socialType && user?.phone ? (
                            ""
                          ) : (
                            <>
                              <div className="col-lg-6 my-2">
                                <div className="form-check druyp_check ps-0 inp_field">
                                  <label
                                    htmlFor="phoneNumber"
                                    className="form-label font-weight-bold"
                                  >
                                    Phone Number
                                  </label>
                                  <input
                                    id="phoneNumber"
                                    type="tel"
                                    className="form-control"
                                    placeholder="Enter your phone"
                                    maxLength={10}
                                    value={inputData?.phone_number}
                                    onKeyPress={(e) => {
                                      if (!/^\d$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) =>
                                      setInputData({
                                        ...inputData,
                                        phone_number: e.target.value,
                                      })
                                    }
                                  />

                                  <div className="phone_icon">
                                    <img src={phone} alt="phone" />
                                  </div>
                                </div>
                                {formError &&
                                  (!inputData?.phone_number ||
                                    inputData?.phone_number?.length < 10) && (
                                    <div className="sap-val-error text-danger mb-0 mt-1">
                                      {!inputData?.phone_number
                                        ? "Please enter your phone number"
                                        : inputData?.phone_number?.length < 10
                                        ? "Invalid phone number"
                                        : ""}
                                    </div>
                                  )}
                              </div>
                            </>
                          )}

                          {socialType && user?.email ? (
                            ""
                          ) : (
                            <div className="col-lg-6 my-2">
                              <div className="form-check druyp_check inp_field ps-0">
                                <label
                                  htmlFor="email"
                                  className="form-label font-weight-bold"
                                >
                                  Email
                                </label>
                                <input
                                  id="email"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your email address"
                                  value={inputData?.email}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) =>
                                    setInputData({
                                      ...inputData,
                                      email: e.target.value,
                                    })
                                  }
                                />
                                <div className="mail_icon">
                                  <img src={mail} alt="mail image" />
                                </div>
                              </div>
                              {formError &&
                                (!inputData?.email ||
                                  !emailRegex.test(inputData?.email)) && (
                                  <div className="sap-val-error text-danger mb-0 mt-1">
                                    {!inputData?.email
                                      ? " Please enter your email address"
                                      : !emailRegex.test(inputData?.email)
                                      ? "Invalid email address"
                                      : ""}
                                  </div>
                                )}
                            </div>
                          )}
                          <div className="col-lg-6 my-2 Spacing-box">
                            <div className="form-check druyp_check ps-0 inp_field">
                              <label
                                htmlFor="pwd"
                                className="form-label font-weight-bold"
                              >
                                Password
                              </label>
                              <input
                                id="pwd"
                                type={pwdEye ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                value={inputData?.pwd}
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    pwd: e.target.value,
                                  })
                                }
                              />
                              <div className="pwd_icon">
                                <img src={PWD} alt="lock image" />
                              </div>

                              <div
                                className="eye_icon"
                                onClick={() => setPwdEye(!pwdEye)}
                              >
                                <img
                                  src={pwdEye ? openEye : closeEye}
                                  alt="eye"
                                />
                              </div>
                            </div>
                            {formError &&
                              (!inputData?.pwd ||
                                !pass.test(inputData?.pwd)) && (
                                <div className="sap-val-error text-danger mb-0 mt-1">
                                  {!inputData?.pwd
                                    ? "Please enter password"
                                    : !pass.test(inputData?.pwd)
                                    ? "Enter a valid password. (Must be of atleast 8 characters and must contains atleast one uppercase, lowercase character and a digit.)"
                                    : ""}
                                </div>
                              )}
                          </div>

                          <div className="col-lg-6 my-2 Spacing-box">
                            <div className="form-check druyp_check inp_field ps-0">
                              <label
                                htmlFor="conPwd"
                                className="form-label font-weight-bold"
                              >
                                Confirm Password
                              </label>
                              <input
                                id="conPwd"
                                type={conPwdEye ? "text" : "password"}
                                className="form-control "
                                placeholder="Confirm password"
                                value={inputData?.conPwd}
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    conPwd: e.target.value,
                                  })
                                }
                              />
                              <div className="pwd_icon">
                                <img src={PWD} alt="lock image" />
                              </div>

                              <div
                                className="eye_icon"
                                onClick={() => setConPwdEye(!conPwdEye)}
                              >
                                <img
                                  src={conPwdEye ? openEye : closeEye}
                                  alt="Eye"
                                />
                              </div>
                            </div>
                            {formError &&
                              (!inputData?.conPwd ||
                                inputData?.pwd !== inputData?.conPwd) && (
                                <div className="sap-val-error text-danger mb-0 mt-1">
                                  {!inputData?.conPwd
                                    ? "Please confirm password"
                                    : inputData?.pwd !== inputData?.conPwd
                                    ? "Password should match!"
                                    : ""}
                                </div>
                              )}
                          </div>

                          <div className="col-12">
                            <div className="form-check druyp_check check_acc">
                              <input
                                id="agreementCheck"
                                type="checkbox"
                                className="form-check-input"
                                value="agreement"
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    agreementCheck: e.target.checked,
                                  })
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="agreementCheck"
                                style={{ fontWeight: "normal" }}
                              >
                                I Accept Druyp Agreement & Terms of use
                              </label>
                            </div>
                            {formError && !inputData?.agreementCheck && (
                              <div className="sap-val-error text-danger mb-0 mt-1">
                                Please accept our terms and conditions.
                              </div>
                            )}

                            {signUpError && (
                              <p className="alert-sec">{signUpError}</p>
                            )}

                            <div className="druyp_login_btn">
                              <button
                                type="button"
                                onClick={() => handleSubmit()}
                              >
                                SIGN UP {btnLoader && <Spinner />}
                              </button>
                              {/* <Button
                                type="submit"
                                onClick={handleformsubmit}
                                isLoading={isButtonLoading}
                                disabled={isButtonLoading}
                              >
                                {socialType ? "CONTINUE" : "SIGN UP"}
                              </Button> */}
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="or_with">Or Register with</div>
                            <div className="social_icon_main">
                              <a
                                className="social_icon"
                                onClick={handleFacebookLogin}
                              >
                                <img src={facebookIcon} alt="Facebook Icon" />
                              </a>
                              <a
                                onClick={handleGoogleLogin}
                                className="social_icon"
                              >
                                <img src={googleIcon} alt="Google Icon" />
                              </a>
                              <a
                                className="social_icon"
                                onClick={handleAppleSignIn}
                              >
                                <img src={appleIcon} alt="Apple icon" />
                              </a>
                            </div>
                            <div className="create_new">
                              Already have an account ?{" "}
                              <a
                                className="druyp_fpasword"
                                onClick={() => {
                                  navigate("/login");
                                }}
                              >
                                Login
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
