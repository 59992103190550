import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";

import { useUserState } from "../Contexts/UserContext";
import furnishedSvg from "../assets/images/furnished.svg";
import buildingSvg from "../assets/images/Building.svg";
import BathTubSvg from "../assets/images/Bathtub.svg";
import RulerSvg from "../assets/images/ruler.svg";
import BedImage from "../assets/images/Bed.svg";
import HouseDummyImg from "../assets/images/houseDummy.webp";


function FavoriteHouses() {
  const { user } = useUserState();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user?.token) {
      fetchData();
    }
  }, [user?.token]);

  const headers = {
    Authorization: user?.token,
  };

  const fetchData = async () => {
    const response = await axios.get(
      `${import.meta.env.VITE_APP_BASE_URL}/api/house/get-favorite-house`,
      {
        headers: headers,
      }
    );
    setIsLoader(false);
    if (response) {
      setData(response?.data?.data);
    } else {
      alert("error");
    }
  };


  return (
    // <main>
    //   <style>
    //     {`
    //       .palm_step {
    //         flex-wrap: wrap;
    //       }
    //       .palm_step li {
    //         margin: 0 13px 13px 0;
    //       }
    //     `}
    //   </style>
    //   <div className="container-fluid px-4">
    //     <div className="row">
    //       <div className="col-md-12 col-12">
            <div className="main-box">
              <div className="content-header">
                <h3> Favorite Houses </h3>
              </div>
              {data?.length > 0 &&
                data?.map((value, i) => (
                  <div key={i + "_houses"} className="row">
                    <div className="col-12">
                      <div className="hist_main">
                        <div
                          className="hist_img"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/property_detail/${value?.house_id}`)
                          }
                        >
                          <div className="brook_house">
                            {value?.MyHouse?.houseImages?.length > 0 && (
                              <img
                                style={{ width: "250px", height: "212px" }}
                                src={
                                  import.meta.env.VITE_APP_BASE_URL +
                                  value?.MyHouse?.houseImages[0]?.imageUrl
                                }
                                onError={(e) => {e.target.src = HouseDummyImg}}
                                alt="img"
                              />
                            )}
                          </div>
                        </div>
                        {value?.MyHouse && (
                          <div className="hist_cont">
                            <div
                              className="house_drop"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/property_detail/${value?.house_id}`)
                              }
                            >
                              <h4>{value?.MyHouse.name}</h4>
                            </div>
                            <p>
                              {/* {value?.MyHouse?.full_address} */}
                              <br />
                              {/* {value?.MyHouse?.state},{" "} */}
                              {/* {value?.MyHouse?.zip_code},{" "} */}
                              {/* {value?.MyHouse.country} */}
                            </p>
                            <h4>
                              ${value?.MyHouse?.monthly_price + value?.MyHouse?.
                                total_utilities_price
                              }/
                              <span className="per_month">per month</span>
                            </h4>
                            <ul className="palm_step">
                              <li>
                                <img src={furnishedSvg} alt="furnished" />
                                <h4>{value?.MyHouse?.house_facility}</h4>
                              </li>
                              <li>
                                <img src={buildingSvg} alt="furnished" />
                                <h4>{value.MyHouse.floor}</h4>
                              </li>
                              <li>
                                <img src={RulerSvg} alt="furnished" />
                                <h4>{value?.MyHouse?.area} SQ FT</h4>
                              </li>
                              <li>
                                <img src={BedImage} alt="furnished" />
                                <h4>{value?.MyHouse?.bedroom_no} Bedrooms</h4>
                              </li>
                              <li>
                                <img src={BathTubSvg} alt="furnished" />
                                <h4>{value?.MyHouse?.bathroom_no} Bathrooms</h4>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <hr className="m-0" />
                    </div>
                  </div>
                ))}

              {isLoader && (
                <div className="eye-loader-frame m-5 p-5">
                  <div className="eye-loader-spinner"></div>
                </div>
              )}

              {data?.length == 0 && !isLoader && (
                <div className="loading-data m-5 p-5">
                  There is no house in your favorite list yet.
                </div>
              )}
            </div>
    //       </div>
    //     </div>
    //   </div>
    // </main>
  );
}

export default FavoriteHouses;
