import { Fragment } from "react";

import Property6Img from "../../../assets/images/property6.png";
import Property4Img from "../../../assets/images/property4.png";
import Property7Img from "../../../assets/images/property7.png";
import Property5Img from "../../../assets/images/property5.png";
import WhyChooseImg from "../../../assets/images/why-choose.png";

import "./PropertyGallery.css";

const PropertyGallery = () => {
  return (
    <Fragment>
      <div className="property-gallery">
        <div className="container-fluid my-container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="heading-sec">
                <h2>
                  Personalize your <span>home.</span> <br />
                  Rent a <span>customizable property</span>+
                </h2>
                <a href="/properties?house_facility=customize">
                  view all customizable properties
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-12">
              <div className="grid-outlet">
                <div className="grid-container">
                  <div className="item1">
                    <img src={Property6Img} />
                    {/* <a href="#">
                      <i className="fa fa-heart-o"></i>
                    </a> */}
                  </div>
                  <div className="item2">
                    <img src={Property4Img} />
                    {/* <a href="#">
                      <i className="fa fa-heart-o"></i>
                    </a> */}
                  </div>
                  <div className="item3">
                    <img src={Property7Img} />
                    {/* <a href="#">
                      <i className="fa fa-heart-o"></i>
                    </a> */}
                  </div>
                  <div className="item4">
                    <img src={Property5Img} />
                    {/* <a href="#">
                      <i className="fa fa-heart-o"></i>
                    </a> */}
                  </div>
                  <div className="item5">
                    <img src={WhyChooseImg} />
                    {/* <a href="#">
                      <i className="fa fa-heart-o"></i>
                    </a> */}
                  </div>
                  <div className="item6">
                    <img src={Property6Img} />
                    {/* <a href="#">
                      <i className="fa fa-heart-o"></i>
                    </a> */}
                  </div>
                  <div className="item7">
                    <img src={Property4Img} />
                    {/* <a href="#">
                      <i className="fa fa-heart-o"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PropertyGallery;
