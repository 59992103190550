import { Fragment } from "react";
import gplay from "../../../assets/images/gplay.png";
import AppStore from "../../../assets/images/app-store.png";
import druypMobile from "../../../assets/images/druyp-mobile.png";
import Slider from "react-slick";



const TestimonialSec = () => {
  
  return (
    <Fragment>
        {/* <div className="testimonials-sec app-promo">
          <div className="container-fluid my-container">
            <div className="row">
              <div className="col-12 col-lg-7 col-md-7">
                <div className="app-content">
                  <h2>Download<span> Our App</span></h2>
                  <p>Find everything you need for buying, selling & renting property in our new Finder App!</p>
                  <div className="app-button-sec">
                    <a href="#"><img src={gplay} /></a>
                    <a href="#"><img src={AppStore} /></a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 col-md-5">
                <div className="app-img">
                  <img src={druypMobile} />
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </Fragment>
  );
};

export default TestimonialSec;
