import furnithistparkImage from "@images/furnithistpark.png";
import furnitfridaImage from "@images/furnitfrida.png";
import furnitfrida2Image from "@images/furnitfrida2.png";
import account2Image from "@images/account2.png";
import callImage from "@images/call.png";
import messageImage from "@images/message.png";
import profileImage from "@images/profile.png";
import cleaningimgImage from "@images/cleaningimg.png";

import locationPinImage from "@images/location pin.png";
import basiccleanhistimgImage from "@images/basiccleanhistimg.png";
import deluxcleanhistimgImage from "@images/deluxcleanhistimg.png";
import histhomeimg from "../../../assets/images/houseDummy.webp";
import { useUserState } from "../../../Contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const CleaningHistory = () => {
  const { user, isLoggedIn } = useUserState();
  const navigate = useNavigate();
  const [userHistory, setUserHistory] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [myCleaningsData, setMyCleaningsData] = useState();

  useEffect(() => {
    if (user?.token) {
      ApiCallFunction();
    }
  }, [user?.token]);
  const headers = {
    Authorization: user?.token,
  };
  console.log("user?.currentRole", user?.currentRole);

  const optimizeDataTransformation = (data) => {
    const result = data.map(
      ({
        cleaningServiceCarts,
        user,
        house_cleaned,
        lease_start_date,
        lease_end_date,
        house_data,
      }) => {
        const cleaningServiceDataMap = new Map();

        cleaningServiceCarts.forEach((ele) => {
          const categoryId = ele.cleaning_service_category_id;
          const cleaningServiceCartsId = [];
          const frequency = ele.frequency;
          const cleaning_cart = ele.cleaning_cart.map((cleaningItem) => ({
            id: cleaningItem.id,
            house_cleaned: cleaningItem.house_cleaned,
            selected_date: cleaningItem.selected_date,
            completed_at: cleaningItem.completed_at,
          }));
          cleaningServiceCartsId.push({
            id: ele.id,
            frequency,
            completed: ele.cleaning_cart.filter(
              (d) => d.house_cleaned === "completed"
            ).length,
          });
          const completedService = cleaningServiceCartsId
            .map(({ completed }) => completed)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );

          if (cleaningServiceDataMap.has(categoryId)) {
            // If category ID already exists, add the frequency and cleaning cart
            const existingData = cleaningServiceDataMap.get(categoryId);
            existingData.frequency += frequency;
            existingData.completedService += completedService;
            existingData.cleaningServiceCartsId.push(...cleaningServiceCartsId);
            existingData.cleaning_cart.push(...cleaning_cart);
          } else {
            // If category ID is not present, set the frequency and cleaning cart
            cleaningServiceDataMap.set(categoryId, {
              frequency,
              completedService,
              cleaningServiceCartsId,
              cleaning_cart,
            });
          }
        });

        const main_cart_id = cleaningServiceCarts[0].main_cart_id;
        const cleaningServiceData = Array.from(
          cleaningServiceDataMap.entries()
        ).map(([categoryId, data]) => ({
          cleaning_service_category_id: categoryId,
          frequency: data.frequency,
          remainingService: data.frequency - data.completedService,
          cleaningServiceCartsId: data.cleaningServiceCartsId,
          cleaning_cart: data.cleaning_cart,
          cleaning_category_name: cleaningServiceCarts.find(
            (ele) => ele.cleaning_service_category_id === categoryId
          ).cleaning_category_data.name,
        }));

        return {
          cleaningServiceData,
          house_cleaned,
          main_cart_id,
          lease_start_date,
          lease_end_date,
          house_detail: {
            name: house_data.name,
            full_address: `${house_data.full_address.replaceAll(";", ",")}, ${
              house_data.state
            }, ${house_data.country}-${house_data.zip_code}`,
          },
          user: {
            user_name: `${user.first_name} ${user.last_name}`,
            phone_number: user.phone_number,
            email: user.email,
            user_image: user.profile_img,
          },
        };
      }
    );

    return result;
  };
  const FetchData = async (url, method) => {
    try {
      const baseCartUrl = `${import.meta.env.VITE_APP_BASE_URL}${url}`;
      const response = await axios({
        method,
        url: baseCartUrl,
        headers,
      });
      return response.data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const ApiCallFunction = async () => {
    setIsLoader(true);
    let data = await FetchData(
      "/api/transactions/get-by-user-cleaning-owner",
      "get"
    );
    setMyCleaningsData(optimizeDataTransformation(data));
    setIsLoader(false);
  };
  const DateConverter = (dates) => {
    return dates.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div className="main-box">
      <div className="content-header">
        <h3>My History</h3>
      </div>
      {isLoader ? (
        <div className="eye-loader-frame m-5 p-5">
          <div className="eye-loader-spinner"></div>
        </div>
      ) : (
        <>
          {myCleaningsData && myCleaningsData.length > 0 ? (
            myCleaningsData.map((cleaning, index) => (
              <div key={index} className="furnit_hist">
                <div className="furnit_hist_left">
                  <div className="furnit_book_profile_cont">
                    <div className="profile-sec">
                      <img
                        src={cleaning?.user?.user_image || profileImage}
                        onError={(e) => {
                          e.target.src = profileImage;
                        }}
                        alt="profile"
                      />
                    </div>
                    <ul className="furnit_book_inner_cont">
                      <li>
                        <img src={account2Image} alt="account2" />
                        <p>{cleaning.user?.user_name}</p>
                      </li>
                      <li>
                        <img src={callImage} alt="call" />
                        <p>{cleaning.user?.phone_number}</p>
                      </li>
                      <li>
                        <img src={messageImage} alt="message" />
                        <p>{cleaning.user?.email}</p>
                      </li>
                      <li>
                        <i className="fa fa-house fa-2x"></i>
                        <p>{cleaning.house_detail?.name}</p>
                      </li>
                      <li>
                        <img src={locationPinImage} alt="location" />
                        <p>{cleaning.house_detail?.full_address}</p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="furnit_hist_right">
                  <div className="furnit_hist_plan_cont">
                    <img src={basiccleanhistimgImage} alt="cleaning plan" />
                    <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
                      <h4>Cleaning Plan</h4>
                      <h4>
                        Date:{" "}
                        {DateConverter(new Date(cleaning.lease_start_date))} to{" "}
                        {DateConverter(new Date(cleaning.lease_end_date))}
                      </h4>
                      <h2> Cleaning Services</h2>
                      {cleaning.cleaningServiceData?.map((service) => (
                        <div
                          className="furnit_delux"
                          key={service.cleaning_service_category_id}
                        >
                          <span>
                            {service.cleaning_category_name} :{" "}
                            {service.frequency}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <hr className="m-0" />

                {/* Additional Cleaning Service Details */}
                {/* <div className="furnit_booking_cont">
                  <div className="furnit_delux">
                    {cleaning.cleaningServiceData?.map((service) => (
                      <div
                        key={service.cleaning_service_category_id}
                        style={{
                          display: "flex",
                          minWidth: "20rem",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <span>{service.cleaning_category_name}:</span>
                        <span>{service.remainingService} (Remaining)</span>
                      </div>
                    ))}
                  </div>
                </div> */}
                {/* <hr className="m-0" /> */}
              </div>
            ))
          ) : (
            <div>There are no bookings</div>
          )}
        </>
      )}
      {/* <div className="furnit_hist">
        <div className="furnit_hist_left">
          <div className="furnit_book_profile_cont">
            <div className="profile-sec">
              <img src={furnithistparkImage} alt="furnithistpark" />
            </div>
            <ul className="furnit_book_inner_cont">
              <li>
                <img src={account2Image} alt="account2" />
                <p>Peter Parker</p>
              </li>
              <li>
                <img src={callImage} alt="call" />
                <p>+1 5623 895 XXX</p>
              </li>
              <li>
                <img src={messageImage} alt="message" />
                <p>peter123@gmail.com</p>
              </li>
              <li>
                <img src={locationPinImage} alt="message" />
                <p>42 Broadway, New York, NY 10004, USA</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="furnit_hist_right">
          <div className="furnit_hist_plan_cont">
            <img src={basiccleanhistimgImage} alt="furnit" />
            <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
              <h4>Basic Cleaning Plan</h4>
              <h4>Date : 1 Mar 2022 to 20 July 2022</h4>
              <h4>Cleaning Type : Weekly</h4>
              <h2>View Basic Cleaning Services</h2>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0" />
      <div className="furnit_hist">
        <div className="furnit_hist_left">
          <div className="furnit_book_profile_cont">
            <div className="profile-sec">
              <img src={furnitfridaImage} alt="furnitfrida" />
            </div>
            <ul className="furnit_book_inner_cont">
              <li>
                <img src={account2Image} alt="account2" />
                <p>Frida</p>
              </li>
              <li>
                <img src={callImage} alt="call" />
                <p>+1 5623 895 XXX</p>
              </li>
              <li>
                <img src={messageImage} alt="message" />
                <p>peter123@gmail.com</p>
              </li>
              <li>
                <img src={locationPinImage} alt="message" />
                <p>42 Broadway, New York, NY 10004, USA</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="furnit_hist_right">
          <div className="furnit_hist_plan_cont">
            <img src={deluxcleanhistimgImage} alt="furnit" />
            <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
              <h4>Delux Cleaning Plan</h4>
              <h4>Date : 1 Apr 2022 to 30 Dec 2022</h4>
              <h4>Cleaning Type : Weekly</h4>
              <h2>View Basic Cleaning Services</h2>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0" />
      <div className="furnit_hist">
        <div className="furnit_hist_left">
          <div className="furnit_book_profile_cont">
            <div className="profile-sec">
              <img src={furnitfrida2Image} alt="furnitfrida2" />
            </div>
            <ul className="furnit_book_inner_cont">
              <li>
                <img src={account2Image} alt="account2" />
                <p>Frida</p>
              </li>
              <li>
                <img src={callImage} alt="call" />
                <p>+1 5623 895 XXX</p>
              </li>
              <li>
                <img src={messageImage} alt="message" />
                <p>peter123@gmail.com</p>
              </li>
              <li>
                <img src={locationPinImage} alt="message" />
                <p>42 Broadway, New York, NY 10004, USA</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="furnit_hist_right">
          <div className="furnit_hist_plan_cont">
            <img src={basiccleanhistimgImage} alt="furnit" />
            <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
              <h4>Basic Cleaning Plan</h4>
              <h4>Date : 1 Feb 2022 to 26 Oct 2022</h4>
              <h4>Cleaning Type : Weekly</h4>
              <h2>View Delux Furniture Items</h2>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0" /> */}
    </div>
  );
};

export default CleaningHistory;
