import { Fragment, useState, useEffect } from "react";

import { Modal, Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { useUserState } from "../../../Contexts/UserContext";

import aboutUs from "../../../assets/images/about-us.png";
import tickmark from "../../../assets/images/tickmark.svg";
import furnitureBanner from "../../../assets/images/furniture-banner.png";
import Approve from "../../../assets/images/Approve.svg";
import TestimonialSec from "../../property/testimonialSec/TestimonialSec";
import FurnitureBackImg from "../../../assets/images/furniture-back.png";


import "react-datepicker/dist/react-datepicker.css";
import "../../furnitureFront/Furniture.css";

const CustomiseFurniture = () => {
  const { user } = useUserState();
  const navigate = useNavigate();
  const location = useLocation();
  const [furDetailModal, setFurDetailModal] = useState(false);
  const [particularFurInfo, setParticularFurInfo] = useState({});
  const [furCompaniesList, setFurCompaniesList] = useState([]);
  const [companyListLoader, setCompanyListLoader] = useState(false);
  const [furList, setFurList] = useState([]);
  const [furListLoader, setFurListLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFurniture, setSelectedFurniture] = useState([]);
  const [totalPrice, setTotalPrice] = useState(null);
  const [loader, setLoader] = useState(false);

  const headers = {
    Authorization: user?.token,
  };

  const fetchCompaniesList = async () => {
    const apiUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/user/getAllCompany/furniture_company`;
    setCompanyListLoader(true);
    try {
      const res = await axios.get(apiUrl, {
        headers: headers,
      });
      setFurCompaniesList(res?.data?.data);
      setCompanyListLoader(false);
    } catch (error) {
      console.log(
        "Error while fetching furniture company List",
        error?.message
      );
      setCompanyListLoader(false);
    }
  };

  const fetchAllfurnitureList = async (id, array1) => {
    const apiUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/furniture/get-list?categoryId=&companyId=${id}`;
    try {
      const res = await axios.get(apiUrl, {
        headers: headers,
      });
      const mergedArray = [...array1, ...res?.data?.data];
      const uniqueArray = mergedArray?.filter(
        (item, index, self) =>
          index === self?.findIndex((t) => t?.id === item?.id)
      );
      const updatedArray = uniqueArray?.map((item) => ({
        ...item,
        selected: array1?.some((arrItem) => arrItem?.id === item?.id),
        quantity: 1,
      }));
      setSelectedFurniture(updatedArray?.filter((item) => item.selected));
      setFurList(updatedArray);
      setFurListLoader(false);
    } catch (error) {
      console.error(
        "Error while fetching furniture on behalf of company:",
        error?.message
      );
      setFurListLoader(false);
    }
  };

  const handleChooseCompany = async (id) => {
    setSelectedOption(id);
    const apiUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/furniture/get-list?categoryId=${
      location?.state?.furPlanId
    }&companyId=${id}`;
    setFurListLoader(true);
    try {
      const res = await axios.get(apiUrl, {
        headers: headers,
      });
      fetchAllfurnitureList(id, res?.data?.data);
    } catch (error) {
      console.error(
        "Error while fetching furniture on behalf of company:",
        error?.message
      );
      setFurListLoader(false);
    }
  };

  const addTwoDays = (date) => {
    if (date) {
      date.setDate(date.getDate() + 2); // Add two days
      return date.toLocaleDateString("en-US");
    }
  };

  const handleFurCheckbox = (furniture) => {
    const isSelected = selectedFurniture.some(
      (item) => item.id === furniture.id
    );
    if (!isSelected) {
      setSelectedFurniture([...selectedFurniture, {...furniture, quantity: 1}]);
    } else {
      const updatedFurnitureList = selectedFurniture.filter(
        (item) => item.id !== furniture.id
      );
      setSelectedFurniture(updatedFurnitureList);
    }
  };

  const decrementQuantity = (i) => {
    const updatedItems = [...selectedFurniture];
    if (updatedItems[i]?.quantity > 1) {
      updatedItems[i].quantity -= 1;
      setSelectedFurniture(updatedItems);
    }
  };

  const incrementQuantity = (i) => {
    const updatedItems = [...selectedFurniture];
    updatedItems[i].quantity += 1;
    setSelectedFurniture(updatedItems);
  };

  const handleProceed = async () => {
    const finalArray = [];
    if (selectedFurniture?.length > 0) {
      for (let i = 0; i < selectedFurniture?.length; i++) {
        const element = selectedFurniture[i];
        const newObj = {
          furniture_category_id: element.furniture_category_id,
          house_id: location?.state?.houseId,
          furniture_id: element?.id,
          quantity: element?.quantity,
        };
        finalArray.push(newObj);
      }
    }
    let payload = {
      cartId: location?.state?.cartId,
      furData: finalArray,
      dataType: "furniture",
    };

    const apiUrl = `${import.meta.env.VITE_APP_BASE_URL}/api/cart/update-cart/${
      location?.state?.cartId
    }`;

    setLoader(true);
    try {
      const res = await axios.put(apiUrl, payload, {
        headers: headers,
      });
      setLoader(false);
      if (res?.data?.statusCode === 200) {
        navigate(
          `/cleaning-package?cart=${location?.state?.cartId}&&house=${location?.state?.houseId}`,
          {
            state: {
              houseId: location?.state?.houseId,
              cartId: location?.state?.cartId,
              startDate: location?.state?.startDate,
              endDate: location?.state?.endDate,
            },
          }
        );
      }
    } catch (error) {
      setLoader(false);
      console.log("Error while hitting update cart API", error);
    }
  };

  useEffect(() => {
    const totalPriceValue = selectedFurniture.reduce(
      (total, fur) => total + fur.price * fur.quantity,
      0
    );
    setTotalPrice(totalPriceValue);
  }, [selectedFurniture]);

  useEffect(() => {
    handleChooseCompany(location?.state?.companyId);
    fetchAllfurnitureList(location?.state?.companyId);
  }, [user?.token]);

  useEffect(() => {
    fetchCompaniesList();
  }, [user?.token]);

  useEffect(() => {
    if (location?.state?.companyId) {
      setSelectedOption(location?.state?.companyId);
    }
  }, [location?.state?.companyId]);

  useEffect(() => {
    if (
      !location?.state?.houseId ||
      !location?.state?.cartId ||
      !location?.state?.furPlan ||
      !location?.state?.furPlanId ||
      !location?.state?.companyId ||
      !location?.state?.startDate ||
      !location?.state?.endDate
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Fragment>
      <div className="banner-section furniture-main">
        <img src={furnitureBanner} alt="furnitureBanner" />
        <div className="search-home my-container">
          <div className="furniture-about">
            <div className="furniture-img">
              <img src={aboutUs} />
            </div>
            <div className="fur-about-content">
              <h2>Quality Furniture Store On Your Specific Area</h2>

              <p>
                We build and activate brands through cultural insight,
                strvision, and the power of emotion across every.
              </p>
              <div className="overview-amenities">
                <ul>
                  <li>
                    <img src={tickmark} />
                    Mix and match colors, sizes, and designs
                  </li>
                  <li>
                    <img src={tickmark} />
                    Top quality prints using the latest technology
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="negotiate-content">
            <h3>
              {location?.state?.furPlan} Package Offer from different trusted
              partners
            </h3>
            <div className="row">
              {companyListLoader && <Spinner />}
              {furCompaniesList &&
                furCompaniesList?.length > 0 &&
                furCompaniesList?.map((company) => (
                  <div
                    key={company?.id}
                    className={`col-12 col-lg-3 col-md-3 ${
                      selectedOption === company?.id ? "active-company" : ""
                    }`}
                  >
                    <div className="trusted-part-title">
                      <h2>{company?.furniture_company}</h2>
                    </div>
                    <div className="trusted-part-radio">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id={company?.id}
                          value={company.id}
                          checked={selectedOption === company?.id}
                          onChange={(e) => handleChooseCompany(e.target.value)}
                        />
                        <span className="furn-off-num">15% OFF</span>
                        <label
                          className="form-check-label"
                          htmlFor={company?.id}
                        />
                      </div>
                      <div className="furniture-new-price">
                        <p>
                          $230<span>$220</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div> */}
        </div>
      </div>

      {location?.state?.companyId && (
        <>
          <div className="dilivery-time-sec">
            <div className="container-fluid my-container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="search-header">
                    <h2>Your Delivery Date & Time</h2>
                    <p>
                      <i className="fa fa-info" aria-hidden="true"></i>{" "}
                      Furniture will be delivered on the date{" "}
                      {addTwoDays(new Date(location?.state?.startDate))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="furniture-plan">
            <div className="container-fluid my-container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="search-header">
                    <h2>Customize your package</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                  <div className="row">
                    {furListLoader && <Spinner />}
                    {furList &&
                      furList?.length > 0 &&
                      furList?.map((furniture, index) => (
                        <div
                          className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                          key={index}
                        >
                          <div className="property-inner furniture-custom">
                            <div className="custom-fur-img">
                              <a
                                className="btn btn-primary"
                                onClick={() => {
                                  setFurDetailModal(true);
                                  setParticularFurInfo(furniture);
                                }}
                              >
                                <i className="fa fa-info"></i>
                              </a>
                              <img
                                src={
                                  import.meta.env.VITE_APP_BASE_URL +
                                  furniture?.imageUrl
                                }
                                onError={(e) => {
                                  e.target.src = FurnitureBackImg;
                                }}
                              />
                            </div>
                            <div className="custom-fur-content">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={index}
                                  disabled={furniture?.selected}
                                  checked={selectedFurniture.some(
                                    (item) => item?.id === furniture?.id
                                  )}
                                  onChange={() => handleFurCheckbox(furniture)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={index}
                                >
                                  {furniture?.name}
                                </label>
                              </div>
                              <p className="price-sec">${furniture?.price}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div className="add-furniture-item">
                    <ul>
                      {selectedFurniture &&
                        selectedFurniture?.length > 0 &&
                        selectedFurniture?.map((fur, i) => (
                          <li key={i}>
                            <div className="delux_item">
                              <div className="book_item_img">
                                <img
                                  src={
                                    import.meta.env.VITE_APP_BASE_URL +
                                    fur?.imageUrl
                                  }
                                  alt={fur?.name}
                                  onError={(e) => {
                                    e.target.src = FurnitureBackImg;
                                  }}
                                />
                                <div className="zunkel_cont">
                                  <p>{fur?.name}</p>
                                  <div className="swatch-head">
                                    <span className="title-swatch">Color</span>{" "}
                                    <span
                                      className="color-swatch"
                                      style={{
                                        background: `${fur?.color}`,
                                      }}
                                    ></span>
                                  </div>
                                </div>
                              </div>

                              <div className="zunkel_price_head">
                                <div className="add-item">
                                  <button
                                    type="button"
                                    onClick={() => decrementQuantity(i)}
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <input
                                    type="text"
                                    className="qty"
                                    value={fur?.quantity}
                                    onChange={(e) =>
                                      setQuantityInput(e.target.value)
                                    }
                                    readOnly
                                  />
                                  <button
                                    type="button"
                                    onClick={() => incrementQuantity(i)}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>

                                <div className="zunkel_price">
                                  <p>${fur?.price * fur?.quantity}</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>

                    <div className="total-sec">
                      <p>
                        Total <span>${totalPrice}</span>
                      </p>
                    </div>
                    <button
                      type="button"
                      className="druyp-btn"
                      disabled={loader}
                      onClick={handleProceed}
                    >
                      Proceed {loader && <Spinner />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <TestimonialSec />

      <Modal
        size="lg"
        show={furDetailModal}
        onHide={() => setFurDetailModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="negotiate-content">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 col-md-4">
                  <img
                    src={
                      import.meta.env.VITE_APP_BASE_URL +
                      particularFurInfo?.imageUrl
                    }
                    alt={particularFurInfo?.name}
                    onError={(e) => {
                      e.target.src = FurnitureBackImg;
                    }}
                  />
                </div>
                <div className="col-12 col-lg-8 col-md-8">
                  <div className="furniture-content">
                    <h3>{particularFurInfo?.name}</h3>
                    <p className="furn-price">${particularFurInfo?.price} </p>
                    <div className="furniture-content Specific">
                      <h3>Specifications</h3>
                      <ul>
                        <li>
                          <img src={Approve} />
                          <div className="Specific-content">
                            <h3>Brand Name</h3>
                            <p>{particularFurInfo?.brand}</p>
                          </div>
                        </li>
                        <li>
                          <img src={Approve} />
                          <div className="Specific-content">
                            <h3>Color</h3>
                            <span
                              className="color-swatch"
                              style={{
                                background: `${particularFurInfo?.color}`,
                              }}
                            ></span>
                          </div>
                        </li>
                        <li>
                          <img src={Approve} />
                          <div className="Specific-content">
                            <h3>Material</h3>
                            <p>{particularFurInfo?.material}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12 col-md-12">
                  <div className="furniture-content dicription">
                    <h3>Description</h3>
                    <p>{particularFurInfo?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default CustomiseFurniture;
