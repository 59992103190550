import { useState } from "react";

import { Spinner } from "react-bootstrap";
import axios from "axios";

import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import profile from "../assets/images/profile.png";
import back_arrow from "../assets/images/back-arrow.svg";
import editImage from "../assets/images/Edit.png";

function UserEdit({ toggleComponent }) {
  const dispatch = useUserDispatch();
  const { user } = useUserState();
  const [urlData, setUrlData] = useState("");
  const [details, setDetails] = useState({
    first_name: user?.first_name ? user?.first_name : "",
    email: user?.email ? user?.email : "",
    last_name: user?.last_name ? user?.last_name : "",
    Phone: user?.phone_number ? user?.phone_number : "",
    tax_id: user?.tax_id ? user?.tax_id : "",
    company_name: user?.company_name ? user?.company_name : "",
  });
  const [inputError, setInputError] = useState(false);
  const [loader, setLoader] = useState(false);

  const capitalizeFirstLetter = (word) => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  };

  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };
  console.log("details", details);

  const updatePofile = async () => {
    const { first_name, last_name, Phone, tax_id, company_name } = details;
    if (!first_name || !Phone || Phone?.length < 10 || !/^[0-9]+$/.test(Phone)) {
      setInputError(true);
      // eslint-disable-next-line no-undef
      // edit_inner_pet;
    } else {
      setInputError(false);
      let data = {
        first_name: capitalizeFirstLetter(first_name),
        last_name: capitalizeFirstLetter(last_name),
        phone_number: Phone,
        email: user?.email,
        profile_img: urlData ? urlData : user?.profile_img,
        tax_id: tax_id,
        company_name: capitalizeFirstLetter(company_name),
      };
      try {
        setLoader(true);
        const res = await axios.put(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/updateProfile`,
          data,
          { headers: headerJson }
        );
        if (res) {
          setLoader(false);
          const userData = res?.data?.data;
          const user1 = {
            ...user,
            ...userData,
          };
          dispatch({ type: "UPDATE_USER", payload: user1 });

          toggleComponent();
        }
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    }
  };

  const headersFormData = {
    "Content-Type": "multipart/form-data",
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
      let res = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/image/upload`,
        formData,
        { headers: headersFormData }
      );
      setUrlData(import.meta.env.VITE_APP_BASE_URL + res?.data?.data?.imageUrl);
    } catch (err) {
      return;
    }
  };

  return (
    <div className="main-box">
      <div className="row">
        <div className="col-12">
          <div className="content-header back_arrow_edit">
            <div
              onClick={toggleComponent}
              className="back_arrow clickable"
              title="Click here to go back."
            >
              <img src={back_arrow} alt="" />
            </div>
            <h3>Edit Profile </h3>
            <div className="sap_blue_btn">
              <button
                type="button"
                // disabled={loader}
                onClick={updatePofile}
              >
                Save Details {loader && <Spinner />}
              </button>
            </div>
          </div>
          <div className="editp_pet_field ">
            <div className="edit_pimg edit_upload_file">
              <img
                src={
                  urlData
                    ? urlData
                    : user?.profile_img
                      ? user?.profile_img
                      : profile
                }
                alt="profile"
              />
              <div className="Neon Neon-theme-dragdropbox ">
                <input
                  name="files[]"
                  id="filer_input2"
                  accept=".jpg, .png, .jpeg"
                  type="file"
                  onChange={handleFileChange}
                />
                <div className="Neon-input-dragDrop">
                  <div className="Neon-input-inner">
                    <div className="Neon-input-icon">
                      <img src={editImage} alt="edit" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {user.role.includes("furniture_company") ||
              user.role.includes("cleaning_company") ? (
              <div className="edit_inner_pet edit_pet_second row">
                <div className="editp_pet_field_inner">
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your First Name"
                      value={details.first_name}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z\s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          first_name: e.target.value,
                        })
                      }
                    />
                    {inputError && !details?.first_name && (
                      <span className="text-danger">
                        Please enter your first name!
                      </span>
                    )}
                  </div>

                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput2"
                      placeholder="Enter your Last Name"
                      value={details.email}
                      // onKeyPress={(e) => {
                      //   if (!/^[a-zA-Z\s]+$/.test(e.key)) {
                      //     e.preventDefault();
                      //   }
                      // }}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput3"
                      className="form-label"
                    >
                      Phone no.
                    </label>
                    <input
                      className="form-control"
                      id="exampleFormControlInput3"
                      placeholder="Phone no."
                      type="tel"
                      maxLength={10}
                      inputMode="numeric"
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={details.Phone}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          Phone: e.target.value,
                        })
                      }
                    />
                    {inputError &&
                      (!details?.Phone || !/^[0-9]+$/.test(details?.Phone) ||
                        details?.Phone?.length < 10) && (
                        <span className="text-danger">
                          {!details?.Phone
                            ? "Please enter your phone number!"
                            : details?.Phone.length < 10
                              ? "Phone number must be 10 digits long!"
                              : !/^[0-9]+$/.test(details?.Phone)
                                ? "Invalid phone number! Only digits are allowed."
                                : ""}
                        </span>
                      )}
                  </div>

                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput4"
                      className="form-label"
                    >
                      Coverage Area
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput4"
                      placeholder="Enter your Coverage Area"
                      value={details.coverage_area}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z\s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          coverage_area: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput4"
                      className="form-label"
                    >
                      Office Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput4"
                      placeholder="Enter your  Office Address"
                      value={details.coverage_area}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z\s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          coverage_area: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput4"
                      className="form-label"
                    >
                      Contact Person
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput4"
                      placeholder="Enter  Contact Person"
                      value={details.coverage_area}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z\s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          coverage_area: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="edit_inner_pet ">
                <div className="editp_pet_field_inner">
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      {user?.currentRole === "property_manager"
                        ? "Property Manager's First Name"
                        : "First Name"}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder={`Enter your ${user?.currentRole === "property_manager"
                          ? "Property Manager's"
                          : ""
                        } First Name`}
                      value={details.first_name}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z\s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          first_name: e.target.value,
                        })
                      }
                    />
                    {inputError && !details?.first_name && (
                      <span className="text-danger">
                        Please enter your first name!
                      </span>
                    )}
                  </div>

                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      {user?.currentRole === "property_manager"
                        ? "Property Manager's Last Name"
                        : "Last Name"}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput2"
                      placeholder={`Enter your ${user?.currentRole === "property_manager"
                          ? "Property Manager's"
                          : ""
                        } Last Name`}
                      value={details.last_name}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z\s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          last_name: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput3"
                      className="form-label"
                    >
                      Phone no.
                    </label>
                    <input
                      className="form-control"
                      id="exampleFormControlInput3"
                      placeholder="Phone no."
                      type="tel"
                      maxLength={10}
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={details.Phone}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          Phone: e.target.value,
                        })
                      }
                    />
                    {inputError &&
                      (!details?.Phone ||
                        details?.Phone?.length < 10) && (
                        <span className="text-danger">
                          {!details?.Phone
                            ? "Please enter your phone number!"
                            : details?.Phone?.length < 10
                              ? "Invalid phone number!"
                              : ""}
                        </span>
                      )}
                  </div>
                  {["property_manager", "relo"].includes(
                    user?.currentRole
                  ) && (
                      <>
                        <div className="profile_field">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Tax ID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter your Tax ID"
                            value={details.tax_id}
                            onKeyDown={(e) => {
                              if (e.key === " ") {
                                e.preventDefault();
                              }
                            }}
                            onKeyPress={(e) => {
                              if (!/^[a-zA-Z1-9\s]+$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              setDetails({
                                ...details,
                                tax_id: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          {inputError && !details?.tax_id && (
                            <span className="text-danger">
                              Please enter Tax ID!
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  {["property_manager"].includes(user?.currentRole) && (
                    <>
                      <div className="profile_field">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter your Company Name"
                          value={details.company_name}
                          // onKeyDown={(e) => {
                          //   if (e.key === " ") {
                          //     e.preventDefault();
                          //   }
                          // }}
                          onChange={(e) =>
                            setDetails({
                              ...details,
                              company_name: e.target.value,
                            })
                          }
                        />
                        {inputError && !details?.company_name && (
                          <span className="text-danger">
                            Please enter Company Name!
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

  );
}

export default UserEdit;
