import { useState, useEffect, Fragment } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";

import { useUserState } from "../../Contexts/UserContext";

import HeroBannerSec from "./heroBannerSec/HeroBannerSec";
import PropertyDetailSec from "./propertyDetailsSec/PropertyDetailSec";
import SimilarPropertySec from "./similarTopPropertiesSec/SimilarProperty";
import TestimonialSec from "./testimonialSec/TestimonialSec";
import dummyImage from "../../assets/images/property1.png";

import "./propertyDetail.css";

const PropertyDetail = () => {
  const { user } = useUserState();
  const { houseId } = useParams();
  const [data, setData] = useState({});
  const [dataLoader, setDataLoader] = useState(false);

  const [bookingStatus, setBookingstatus] = useState("");

  const fetchData = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    setDataLoader(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_BASE_URL}/api/house/get${
          user?.token ? "-booking-details" : ""
        }/${houseId}`,
        { headers: headersFormData }
      );

      setData({
        ...response?.data?.data,
        order_id: response?.data?.data?.booking_details?.order_id
          ? response?.data?.data?.booking_details?.order_id
          : null,
      });
      setBookingstatus(response?.data.data?.booking_details?.booking_status);
      setDataLoader(false);
    } catch (error) {
      setDataLoader(false);
      console.log("Error while getting property data", error?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Fragment>
      {dataLoader ? (
        <div className="eye-loader-frame m-5 p-5">
          <div className="eye-loader-spinner"></div>
        </div>
      ) : (
        <>
          {/* <HeroBannerSec data={data} /> */}
          <div id="carouselExample" className="carousel slide properity-slider">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
            <div className="carousel-inner">
              {data?.houseImages &&
                data?.houseImages.length > 0 &&
                data?.houseImages?.map((image, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <img
                      className="d-block"
                      src={import.meta.env.VITE_APP_BASE_URL + image.imageUrl}
                      alt={`Slide ${index + 1}`}
                      onError={(e) => {
                        e.target.src = dummyImage;
                      }}
                    />
                  </div>
                ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <PropertyDetailSec data={data} />
        </>
      )}

      {/* <div className="top-property-sec details">
        <SimilarPropertySec />
      </div> */}
      <TestimonialSec />
    </Fragment>
  );
};

export default PropertyDetail;
