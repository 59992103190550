import axios from "axios";
import { useEffect, useState } from "react";
import histhomeimg from "../assets/images/houseDummy.webp";
import checkicon from "../assets/images/checkicon.svg";
import clock from "../assets/images/clock.svg";
import DateIcon from "../assets/images/Date.svg";
import invoice from "../assets/images/invoice.svg";
import histhome2img from "../assets/images/histhome2img.png";
import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
function UserHistory() {
  const { user, isLoggedIn } = useUserState();
  const navigate = useNavigate();
  const [userHistory, setUserHistory] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    if (user?.token) {
      getUserHistory();
    }
  }, [user?.token]);
  const headers = {
    Authorization: user?.token,
  };
  console.log("user?.currentRole", user?.currentRole);
  const getUserHistory = async () => {
 
    const roleApiMap = {
      home_owner: "/get-by-user-house-owner",
      property_manager: "/get-by-user-house-owner",
      tenant: "/get-by-user-tenant",
      relo: "/get-by-user-tenant",
    };

    const ApiEndPoint = roleApiMap[user?.currentRole] || ''; 
    const res = await axios.get(
      `${import.meta.env.VITE_APP_BASE_URL}/api/transactions/${ApiEndPoint}`,
      {
        headers: headers,
      }
    );
    setIsLoader(false);
    if (res) {
      setUserHistory(res?.data?.data);
    } else {
          setIsLoader(false);
      alert("error");
    }
  };
  const imgPath = import.meta.env.VITE_APP_BASE_URL;
 const handleError = (e) => {
    e.target.src = histhomeimg; // Fallback to the dummy image on error
  };
  return (
    // <main>
    //   <div className="container-fluid px-4" id="history-pg">
    //     <div className="row">
    //       <div className="col-md-12 col-12">
            <div className="main-box">
              <div className="content-header">
                <h3>My History</h3>
              </div>
              <div className="row">
                <div className="col-12">
                  {["tenant", "relo"].includes(user?.currentRole)
                    ? userHistory?.map((ele, i) => {
                        return (
                          <>
                            <div
                              className="hist_main"
                              onClick={() =>
                                navigate(
                                  `/property_detail/${ele?.order?.house_id}`
                                )
                              }
                            >
                              <div className="hist_img position-relative">
                                <div className="img_num"> {i + 1} </div>

                                <img
                                  src={
                                    ele.order.house_data.houseImages.length > 0
                                      ? `${imgPath}${ele.order.house_data.houseImages[0].imageUrl}`
                                      : histhomeimg
                                  }
                                  alt="histhomeimg"
                                  onError={handleError}
                                />
                              </div>
                              <div className="hist_cont">
                                <h4>{ele?.order?.house_data?.name}</h4>
                                <p>
                                  {ele?.order?.house_data.country},{" "}
                                  {
                                    ele?.order.house_data.full_address.split(
                                      ";"
                                    )[0]
                                  }
                                  ,{" "}
                                  {
                                    ele?.order.house_data.full_address.split(
                                      ";"
                                    )[1]
                                  }
                                  ,{" "}
                                  {
                                    ele?.order.house_data.full_address.split(
                                      ";"
                                    )[2]
                                  }
                                  , {ele?.order.house_data.state},{" "}
                                  {ele?.order.house_data.zip_code}
                                </p>
                                <div className="month_cont">
                                  <h2>
                                    Starting Month :{" "}
                                    {new Date(
                                      ele?.order?.lease_start_date
                                    )?.toLocaleDateString("en-US")}
                                  </h2>
                                  <h2>
                                    End Month :{" "}
                                    {new Date(
                                      ele?.order?.lease_end_date
                                    )?.toLocaleDateString("en-US")}
                                  </h2>
                                </div>
                                <hr />
                                <div className="payment_cont">
                                  <h4>
                                    <img
                                      src={checkicon}
                                      alt="checkicon"
                                      onError={handleError}
                                    />{" "}
                                    Payment Status: {ele?.status}
                                  </h4>
                                  {/* <h4><img src={clock} alt="clock" /> Time : 9:00AM TO 11:00AM</h4> */}
                                </div>
                                {/* <div className="payment_cont pay_status">
                          <h4><img src={DateIcon} alt="DateIcon" /> Payment Status : Completed</h4>
                          <h4><img src={invoice} alt="invoice" /> Time : 9:00AM TO 11:00AM</h4>
                        </div> */}
                              </div>
                            </div>
                            <hr className="m-0" />
                          </>
                        );
                      })
                    : userHistory?.map((ele) => {
                        return (
                          <>
                            <div className="hist_main">
                              <div className="hist_img">
                                <img
                                  src={
                                    ele.house_data.houseImages.length > 0
                                      ? `${imgPath}${ele.house_data.houseImages[0].imageUrl}`
                                      : histhomeimg
                                  }
                                  alt="histhomeimg"
                                  onError={handleError}
                                />
                              </div>
                              <div className="hist_cont">
                                <h4>{ele?.house_data.name}</h4>
                                <p>
                                  {ele?.house_data.full_address} ,
                                  {ele?.house_data.state} ,
                                  {ele?.house_data.country} ,
                                  {ele?.house_data.zip_code}
                                </p>
                                <div className="month_cont">
                                  <h2>
                                    Starting Month :{" "}
                                    {new Date(
                                      ele?.lease_start_date
                                    )?.toLocaleDateString("en-US")}
                                  </h2>
                                  <h2>
                                    End Month :
                                    {new Date(
                                      ele?.lease_end_date
                                    )?.toLocaleDateString("en-US")}
                                  </h2>
                                </div>
                                <hr />
                                <div className="payment_cont">
                                  <h4>
                                    <img
                                      src={checkicon}
                                      alt="checkicon"
                                      onError={handleError}
                                    />{" "}
                                    Payment Status :complete
                                  </h4>
                                  {/* <h4><img src={clock} alt="clock" /> Time : 9:00AM TO 11:00AM</h4> */}
                                </div>
                                {/* <div className="payment_cont pay_status">
                          <h4><img src={DateIcon} alt="DateIcon" /> Payment Status : Completed</h4>
                          <h4><img src={invoice} alt="invoice" /> Time : 9:00AM TO 11:00AM</h4>
                        </div> */}
                              </div>
                            </div>
                            <hr className="m-0" />
                          </>
                        );
                      })}

                  {isLoader && (
                    <div className="eye-loader-frame m-5 p-5">
                      <div className="eye-loader-spinner"></div>
                    </div>
                  )}

                  {userHistory.length == 0 && !isLoader && (
                    <div className="loading-data">There is no history.</div>
                  )}

                  {/* <div className="hist_main">
                    <div className="hist_img">
                      <img src={histhome2img} alt="histhome2img" />
                    </div>
                    <div className="hist_cont">
                      <h4>Brooklyn Property Housing</h4>
                      <p>42 Broadway, New York, NY 10004, USA , 42 Broadway,<br /> New York, NY 10004, USA</p>
                      <div className="month_cont">
                        <h2>Starting Month : 05/03/2022</h2>
                        <h2>End Month : 04/23/2023</h2>
                      </div>
                      <hr />
                      <div className="payment_cont">
                        <h4><img src={checkicon} alt="checkicon" /> Payment Status : Completed</h4>
                        <h4><img src={clock} alt="clock" /> Time : 9:00AM TO 11:00AM</h4>
                      </div>
                      <div className="payment_cont pay_status">
                        <h4><img src={DateIcon} alt="DateIcon" /> Payment Status : Completed</h4>
                        <h4><img src={invoice} alt="invoice" /> Time : 9:00AM TO 11:00AM</h4>
                      </div>
                    </div>
                  </div> */}
                  <div></div>
                </div>
              </div>
            </div>
    //       </div>
    //     </div>
    //   </div>
    // </main>
  );
}

export default UserHistory;
