import { Fragment, useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Button, Spinner } from "react-bootstrap";
import axios from "axios";

import { useUserState } from "../../../Contexts/UserContext";

import Approve from "../../../assets/images/Approve.svg";
import aboutUs from "../../../assets/images/about-us.png";
import tickmark from "../../../assets/images/tickmark.svg";
import furnitureBanner from "../../../assets/images/furniture-banner.png";
import FurnitureBackImg from "../../../assets/images/furniture-back.png";

import "react-datepicker/dist/react-datepicker.css";
import "./CustomiseFurniture.css";

const CustomisePlan = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useUserState();
  const [furCompaniesList, setFurCompaniesList] = useState([]);
  const [companySelectedStatus, setCompanySelectedStatus] = useState(false);
  const [companyListLoader, setCompanyListLoader] = useState(false);
  const [furList, setFurList] = useState([]);
  const [furListLoader, setFurListLoader] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [companyId, setCompanyId] = useState("");
  const [furDetailModal, setFurDetailModal] = useState(false);
  const [particularFurInfo, setParticularFurInfo] = useState({});
  const [loader, setLoader] = useState(false);
  const [clickHereLoader, setClickHereLoader] = useState(false);

  const headers = {
    Authorization: user?.token,
  };

  const fetchCompaniesList = async () => {
    const apiUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/user/getAllCompany/furniture_company?categoryId=${location?.state?.furPlanId}`;
    setCompanyListLoader(true);
    try {
      const res = await axios.get(apiUrl, {
        headers: headers,
      });
      setFurCompaniesList(res?.data?.data?.filter(item => item.totalPrice > 0));
      setCompanyListLoader(false);
    } catch (error) {
      console.log(
        "Error while fetching furniture company List",
        error?.message
      );
      setCompanyListLoader(false);
    }
  };

  const handleChooseCompany = async (id) => {
    setCompanyId(id);
    setCompanySelectedStatus(true);
    const apiUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/furniture/get-list?categoryId=${
      location?.state?.furPlanId
    }&companyId=${id}`;
    setFurListLoader(true);
    try {
      const res = await axios.get(apiUrl, {
        headers: headers,
      });
      setFurList(res?.data?.data);

      let sum = 0;
      res?.data?.data?.forEach((item) => {
        sum += parseInt(item.price); // Parsing the price to ensure it's treated as a number
      });
      setTotalPrice(sum);
      setFurListLoader(false);
    } catch (error) {
      console.error(
        "Error while fetching furniture on behalf of company:",
        error?.message
      );
      setFurListLoader(false);
    }
  };

  const addTwoDays = (date) => {
    if (date) {
      date.setDate(date.getDate() + 2); // Add two days
      return date.toLocaleDateString("en-US");
    }
  };

  const handleProceed = async () => {
    const finalArray = [];
    if (furList?.length > 0) {
      for (let i = 0; i < furList.length; i++) {
        const element = furList[i];
        const newObj = {
          furniture_category_id: element.furniture_category_id,
          house_id: location?.state?.houseId,
          furniture_id: element?.id,
          quantity: 1,
        };
        finalArray.push(newObj);
      }
    }
    let payload = {
      cartId: location?.state?.cartId,
      furData: finalArray,
      dataType: "furniture",
    };

    const apiUrl = `${import.meta.env.VITE_APP_BASE_URL}/api/cart/update-cart/${
      location?.state?.cartId
    }`;

    setLoader(true);
    try {
      const res = await axios.put(apiUrl, payload, {
        headers: headers,
      });
      setLoader(false);
      if (res?.data?.statusCode === 200) {
        navigate(
          `/cleaning-package?cart=${location?.state?.cartId}&&house=${location?.state?.houseId}`,
          {
            state: {
              houseId: location?.state?.houseId,
              cartId: location?.state?.cartId,
              startDate: location?.state?.startDate,
              endDate: location?.state?.endDate,
            },
          }
        );
      }
    } catch (error) {
      setLoader(false);
      console.log("Error while hitting update cart API", error);
    }
  };

  const clickHereCustomize = async () => {
    setClickHereLoader(true);
    try {
      await axios.delete(
        `${import.meta.env.VITE_APP_BASE_URL}/api/cart/remove-by-carttype`,
        {
          data: {
            dataType: "furniture",
            house_id: location?.state?.houseId,
          },
          headers: headers,
        }
      );
      setClickHereLoader(false);
      navigate(`/customise-furniture`, {
        state: {
          houseId: location?.state?.houseId,
          cartId: location?.state?.cartId,
          furPlan: location?.state?.furPlan,
          furPlanId: location?.state?.furPlanId,
          companyId: companyId,
          startDate: location?.state?.startDate,
          endDate: location?.state?.endDate,
        },
      });
    } catch (error) {
      console.log("Error while hitting remove cart API", error?.message);
      setClickHereLoader(false);
    }
  };

  useEffect(() => {
    fetchCompaniesList();
  }, [user?.token]);

  useEffect(() => {
    if (
      !location?.state?.houseId ||
      !location?.state?.cartId ||
      !location?.state?.startDate ||
      !location?.state?.endDate ||
      !location?.state?.furPlanId ||
      !location?.state?.furPlan
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Fragment>
      <div className="banner-section furniture-main">
        <img src={furnitureBanner} alt="furnitureBanner"  />
        <div className="search-home my-container">
          <div className="furniture-about">
            <div className="furniture-img">
              <img src={aboutUs} />
            </div>
            <div className="fur-about-content">
              <h2>Quality Furniture Store On Your Specific Area</h2>
              <p>
                We build and activate brands through cultural insight,
                strvision, and the power of emotion across every.
              </p>
              <div className="overview-amenities">
                <ul>
                  <li>
                    <img src={tickmark} />
                    Mix and match colors, sizes, and designs
                  </li>
                  <li>
                    <img src={tickmark} />
                    Top quality prints using the latest technology
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="negotiate-content">
            <h3>
              {location?.state?.furPlan} Package Offer from different trusted
              partners
            </h3>
            <div className="row">
              {companyListLoader && (
                <div className="sap-loader-onscreen">
                  <div className="eye-loader-frame m-5 p-5">
                    <div className="eye-loader-spinner"></div>
                  </div>
                </div>
              )}
              {furCompaniesList &&
                furCompaniesList?.length > 0 &&
                furCompaniesList?.map((company) => (
                  <div
                    key={company?.id}
                    className={`col-12 col-lg-3 col-md-3 ${
                      companyId === company?.id ? "active-company" : ""
                    }`}
                  >
                    <div className="trusted-part-title">
                      <h2>{company?.furniture_company}</h2>
                    </div>
                    <div className="trusted-part-radio">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="default"
                          id={company?.id}
                          onChange={() => {
                            handleChooseCompany(company?.id);
                          }}
                        />
                        {/* <span className="furn-off-num">15% OFF</span> */}
                        <label
                          className="form-check-label"
                          htmlFor={company?.id}
                        />
                      </div>
                      <div className="furniture-new-price">
                        <p>
                          {/* $230 */}
                          <span>${company?.totalPrice}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {companySelectedStatus && (
        <>
          <div className="dilivery-time-sec">
            <div className="container-fluid my-container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="search-header">
                    <h2>Your Delivery Date & Time</h2>
                    <p>
                      <i className="fa fa-info" aria-hidden="true"></i>{" "}
                      Furniture will be delivered on{" "}
                      {addTwoDays(new Date(location?.state?.startDate))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="furniture-plan delux">
            <div className="container-fluid my-container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="search-header">
                        <h2>{location?.state?.furPlan} Package</h2>
                        <div className="sel_pac">
                          <h4>
                            Total - <span>${totalPrice}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                    {furListLoader ? (
                      <div className="sap-loader-onscreen">
                        <Spinner />
                      </div>
                    ) : (
                      furList &&
                      furList.length > 0 &&
                      furList.map((fur) => (
                        <div
                          key={fur?.id}
                          className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                        >
                          <div className="property-inner furniture-custom">
                            <div className="custom-fur-img">
                              <a
                                className="btn btn-primary"
                                onClick={() => {
                                  setFurDetailModal(true);
                                  setParticularFurInfo(fur);
                                }}
                              >
                                <i
                                  className="fa fa-info"
                                  aria-hidden="true"
                                ></i>
                              </a>
                              <img
                                src={
                                  import.meta.env.VITE_APP_BASE_URL +
                                  fur?.imageUrl
                                }
                                alt={fur?.name}
                                onError={(e) => {
                                  e.target.src = FurnitureBackImg;
                                }}
                              />
                            </div>
                            <div className="custom-fur-content">
                              <h4>{fur?.name}</h4>
                              <p className="price-sec">${fur?.price}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}

                    {!furListLoader && (
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="sap_prem_btn cleaning">
                          <button type="button" onClick={() => navigate(-1)}>
                            Go Back
                          </button>
                          <button
                            type="button"
                            disabled={loader}
                            onClick={() => handleProceed()}
                          >
                            Proceed {loader && <Spinner />}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div className="customise-package-sec">
                    <div className="custom-contetn">
                      <h4>Modify Your Package?</h4>
                      <Button
                        disabled={clickHereLoader}
                        onClick={() => {
                          clickHereCustomize();
                        }}
                      >
                        Click Here! {clickHereLoader && <Spinner />}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        size="lg"
        show={furDetailModal}
        onHide={() => setFurDetailModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="negotiate-content">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 col-md-4">
                  <img
                    src={
                      import.meta.env.VITE_APP_BASE_URL +
                      particularFurInfo?.imageUrl
                    }
                    onError={(e) => {
                      e.target.src = FurnitureBackImg;
                    }}
                  />
                </div>
                <div className="col-12 col-lg-8 col-md-8">
                  <div className="furniture-content">
                    <h3>{particularFurInfo?.name}</h3>
                    <p className="furn-price">${particularFurInfo?.price} </p>
                    <div className="furniture-content Specific">
                      <h3>Specifications</h3>
                      <ul>
                        <li>
                          <img
                            src={Approve}
                            onError={(e) => {
                              e.target.src = FurnitureBackImg;
                            }}
                          />
                          <div className="Specific-content">
                            <h3>Brand Name</h3>
                            <p>{particularFurInfo?.brand}</p>
                          </div>
                        </li>
                        <li>
                          <img
                            src={Approve}
                            onError={(e) => {
                              e.target.src = FurnitureBackImg;
                            }}
                          />
                          <div className="Specific-content">
                            <h3>Color</h3>
                            <span
                              className="color-swatch"
                              style={{
                                background: `${particularFurInfo?.color}`,
                              }}
                            ></span>
                          </div>
                        </li>
                        <li>
                          <img
                            src={Approve}
                            onError={(e) => {
                              e.target.src = FurnitureBackImg;
                            }}
                          />
                          <div className="Specific-content">
                            <h3>Material</h3>
                            <p>{particularFurInfo?.material}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-12 col-md-12">
                  <div className="furniture-content dicription">
                    <h3>Description</h3>
                    <p>{particularFurInfo?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default CustomisePlan;
