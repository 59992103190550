import { Fragment, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { useUserState, useUserDispatch } from "../../../Contexts/UserContext";
import TermConditionModal from "../../termsConPrivacyModals/TermConditionModal";
import PrivacyModal from "../../termsConPrivacyModals/PrivacyModal";

import LogoSvg from "../../../assets/images/logo.svg";
import ApproveImg from "../../../assets/images/Approve.png";
import MailImg from "../../../assets/images/mailmessage.png";
import InstaImg from "../../../assets/images/instagram.png";
import TelephoneImg from "../../../assets/images/telephone.png";
import TwitterImg from "../../../assets/images/twitter.png";

import "./FooterStyle.css";

const Footer = () => {
  const { user } = useUserState();
  const dispatch = useUserDispatch();
  const currentRoute = useLocation();
  const [termConModal, setTermConModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  const otherRoles = user?.role?.filter((r) => r !== user?.currentRole);

  const handleScroll = () => {
    if (currentRoute?.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  return (
    <Fragment>
      <footer>
        <section className="foot_sec">
          <div className="container-fluid">
            <div className="foot_sec_inner">
              <div className="foot_cont">
                <div className="foot_logo">
                  <Link to="/" onClick={() => handleScroll()}>
                    <img src={LogoSvg} />
                  </Link>
                </div>
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old.
                </p>
              </div>
              <div className="foot_cont">
                <div className="foot_head">Druyp</div>
                <ul>
                  <li>
                    <Link to="/" onClick={() => handleScroll()}>
                      <img src={ApproveImg} /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/properties">
                      <img src={ApproveImg} /> Get a Choice
                    </Link>
                  </li>
                  {/* <li>
                    <a href="#">
                      <img src={ApproveImg} /> Download
                    </a>
                  </li> */}
                  <li>
                    <Link to="/contact-us">
                      <img src={ApproveImg} /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              {user &&
                user?.role?.length <= 3 &&
                user?.role?.length > 1 &&
                !user?.role?.includes("furniture_company") &&
                !user?.role?.includes("cleaning_company") &&
                !user?.role?.includes("relo") && (
                  <div className="foot_cont">
                    <div className="foot_head">Host</div>
                    <ul>
                      {otherRoles.map((r, index) => (
                        <li key={index}>
                          <Link
                            to={
                              r === "tenant"
                                ? "/user_dash/tenant"
                                : r === "home_owner"
                                ? "/user_dash/home_owner"
                                : r === "property_manager"
                                ? "/user_dash/property_manager"
                                : ""
                            }
                            onClick={() => {
                              const updatedUserRole = {
                                ...user,
                                currentRole: r,
                              };
                              // Dispatch an action to update the user in the context
                              dispatch({
                                type: "UPDATE_USER",
                                payload: updatedUserRole,
                              });
                            }}
                          >
                            <img src={ApproveImg} />{" "}
                            {r === "tenant"
                              ? "Tenant"
                              : r === "home_owner"
                              ? "Home Owner"
                              : r === "property_manager"
                              ? "Property Manager"
                              : ""}
                          </Link>
                        </li>
                      ))}
                      {/* {user?.currentRole === "tenant" ? (
                        <Link
                          to="/user_dash/home_owner"
                          onClick={() => {
                            const updatedUserRole = {
                              ...user,
                              currentRole: "home_owner",
                            };
                            // Dispatch an action to update the user in the context
                            dispatch({
                              type: "UPDATE_USER",
                              payload: updatedUserRole,
                            });
                          }}
                        >
                          <img src={ApproveImg} /> House Owner
                        </Link>
                      ) : user?.currentRole === "home_owner" ? (
                        <Link
                          to="/user_dash/tenant"
                          onClick={() => {
                            const updatedUserRole = {
                              ...user,
                              currentRole: "tenant",
                            };
                            // Dispatch an action to update the user in the context
                            dispatch({
                              type: "UPDATE_USER",
                              payload: updatedUserRole,
                            });
                          }}
                        >
                          <img src={ApproveImg} /> Tenant
                        </Link>
                      ) : (
                        ""
                      )} */}

                      {/* <li>
                    <a href="#">
                      <img src={ApproveImg} /> Furniture Company
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={ApproveImg} /> Cleaning Company
                    </a>
                  </li> */}
                    </ul>
                  </div>
                )}

              <div className="foot_cont">
                <div className="foot_head">Other Links</div>
                <ul>
                  <li>
                    <Link to="/about-us">
                      <img src={ApproveImg} /> About Us
                    </Link>
                  </li>
                  <li>
                    <button onClick={() => setTermConModal(true)}>
                      <img src={ApproveImg} /> Terms &amp; Conditions
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setPrivacyModal(true)}>
                      <img src={ApproveImg} /> Privacy Policy
                    </button>
                  </li>
                </ul>
              </div>
              <div className="foot_cont">
                <div className="foot_head">Contact us</div>
                <ul>
                  <li>
                    <a href="#">
                      <img src={TelephoneImg} /> (217) 443-4545
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={MailImg} /> info@druyp.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={InstaImg} /> @druyp_insta
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <img src={TwitterImg} /> @druyp_twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="m-0" />
          <div className="foot_copyright">
            <p>©{new Date().getFullYear()} All rights reserved by Druyp</p>
          </div>
        </section>
      </footer>

      <TermConditionModal
        termConModal={termConModal}
        setTermConModal={setTermConModal}
      />
      <PrivacyModal
        privacyModal={privacyModal}
        setPrivacyModal={setPrivacyModal}
      />
    </Fragment>
  );
};

export default Footer;
