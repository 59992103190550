import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./propertySearch.css";
import axios from "axios";
import slide3 from "@images/slide3.png";
import LocationSvg from "@images/location.svg";
import bed from "@images/Bed.svg";
import bathroom from "@images/Bathtub.svg";
import ruler from "@images/ruler.svg";

import heart from "@images/heart.svg";

import redheart from "@images/heart-red.svg";
import { useUserState } from "../../../Contexts/UserContext";
// import HouseDummyImg from "../../assets/images/houseDummy.webp";
import HouseDummyImg from "../../../assets/images/houseDummy.webp";
import CustomPagination from "./CustomPagination";
import PropertyTypeFilter from "./PropertyTypeFilter ";

function PropertySearch() {
  const { user, isLoggedIn } = useUserState();
  const headers = {
    Authorization: user?.token,
  };
  const location = useLocation();
  const navigate = useNavigate();
  const locationSearch = new URLSearchParams(location.search);
  const [queryParams, setQueryParams] = useState({
    state: locationSearch.get("state") || "",
    type: locationSearch.get("type") || "",
    name: locationSearch.get("name") || "",
    amenities: locationSearch.get("amenities") || "",
    bedroom_no: locationSearch.get("bedroom_no") || "",
    bathroom_no: locationSearch.get("bathroom_no") || "",
    full_address: locationSearch.get("full_address") || "",
    minarea: locationSearch.get("minarea") || "",
    maxarea: locationSearch.get("maxarea") || "",
    minprice: locationSearch.get("minprice") || "",
    maxprice: locationSearch.get("maxprice") || "",
    page_no: locationSearch.get("page_no") || 1,
    house_type: locationSearch.get("house_type") || "",
    house_facility: locationSearch.get("house_facility") || "",
    sort: "",
    searchKey: locationSearch.get("searchKey") || "",
  });
  const [houseData, setHouseData] = useState([]);
  const [order, setOrder] = useState("");
  const [sortVal, setSortVal] = useState("");
  const [locSearch, setLocSearch] = useState(null);
  const [imageSrc, setImageSrc] = useState(heart);
  const [pageNo, setPageNo] = useState(locationSearch.get("page_no") || 1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(12);
  const [isLoader, setIsLoader] = useState(false);
  const [allSelected, setAllSelected] = useState({});
  const [isFavorite, setIsFavorite] = useState({});

  // Sync queryParams with URL params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const updatedParams = { ...queryParams };

    // Update queryParams based on URL query
    Object.keys(updatedParams).forEach((key) => {
      if (searchParams.get(key)) {
        updatedParams[key] = searchParams.get(key);
      }
    });
    setQueryParams(updatedParams);
  }, [location.search]);

  const sortKey = (sortVal, order) => {
    let key = `${sortVal}${order}`;
    if (!sortVal) {
      key = order === "Desc" ? "new" : "old";
    }
    const updatedParams = { ...queryParams, sort: key, page_no: 1 };
    // setQueryParams((prev) => {
    //   return { ...prev, sort: key };
    // });
    setPageNo(1);
    setQueryParams(updatedParams);
    updateQueryParamsInURL(updatedParams);
  };

  const addFav = async (parameter) => {
    try {
      setIsFavorite((prevState) => ({
        ...prevState,
        [parameter]: !prevState[parameter], // Toggle the favorite status of the specific house ID
      }));
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/house/favorite-house/${parameter}`,
        {
          headers: headers,
        }
      );
      if (response) {
        if (response.data.message == true) {
          setImageSrc(redheart);
        } else {
          setImageSrc(heart);
        }
      }
      setIsLoader(false);
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  const searchData = async () => {
    const apiUrl = isLoggedIn
      ? `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/search/propertySearchWithUserId`
      : `${import.meta.env.VITE_APP_BASE_URL}/api/search/propertySearch`;

    //alert(apiUrl);
    try {
      setIsLoader(true);
      let data = await axios.get(apiUrl, {
        headers: headers,
        params: queryParams,
      });
      setHouseData([...data.data.results]);
      setTotalItems(data?.data?.totalResults);
      setIsLoader(false);
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      return;
    }
  };
useEffect(()=>{
  if(houseData.length && isLoggedIn){
    const favorites = Object.fromEntries(
      houseData.map((item) => [item.id, item.isFavorite])
    );
    setIsFavorite(favorites);
  }
},[houseData])

  const updateQueryParamsInURL = (updatedParams) => {
    // Filter out any parameters with null, undefined, or empty string values
    const filteredParams = Object.keys(updatedParams)
      .filter(
        (key) =>
          updatedParams[key] !== null &&
          updatedParams[key] !== "" &&
          updatedParams[key] !== undefined
      )
      .reduce((obj, key) => {
        obj[key] = updatedParams[key];
        return obj;
      }, {});

    // Convert the filtered parameters to a query string
    const newSearchParams = new URLSearchParams(filteredParams).toString();

    // Update the URL with the new query string
    navigate({
      pathname: location.pathname,
      search: newSearchParams ? `?${newSearchParams}` : "", // Avoid adding `?` if there are no query params
    });
  };

  // Handle query parameter changes
  const handleChange = (key, value) => {
    const updatedParams = { ...queryParams, [key]: value, page_no: 1 };
    setPageNo(1);
    setQueryParams(updatedParams);
    updateQueryParamsInURL(updatedParams); // Update URL with new query params
  };

  useEffect(() => {
    const updatedParams = { ...queryParams, page_no: pageNo };
    setQueryParams(updatedParams); // Update queryParams with updatedParams
    updateQueryParamsInURL(updatedParams);
  }, [pageNo]); // Dependency array should track `page`

  const allSelectFunction = () => {
    const filteredParams = Object.keys(queryParams).reduce((obj, key) => {
      if (queryParams[key] === "") {
        obj[key] = true;
      } else {
        obj[key] = false;
      }
      return obj;
    }, {});
    return filteredParams;
  };

  // Call the searchData function whenever queryParams change
  useEffect(() => {
    searchData();
    setAllSelected(allSelectFunction());
  }, [queryParams]);
  return (
    <div>
      <div className="banner-section Dream-Home">
        <img src={slide3} alt="..." />
        
        <div className="my-container search-home">
          <div className="search-header">
            <h2>
              Find Your <span>Dream Home</span>
            </h2>
            <p>We’ve more than 745,000 apartments, place & plot.</p>
          </div>
          <div className="property-search search-form">
            <div className="input-sec">
              <label className="form-label">
              Location/House <span className="star_symb">*</span>
              </label>
              <img src={LocationSvg} />
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your House Location"
                value={queryParams.searchKey}
                onChange={(e) => {
                  setQueryParams((prev) => {
                    return { ...prev, searchKey: e.target.value };
                  });
                }}
              />
            </div>
            {/* <div
              className="input-sec button-sec"
              onClick={() => {
                setQueryParams((prev) => {
                  return { ...prev, searchKey: locSearch };
                });
              }}
            >
              <a>Search</a>
            </div> */}
          </div>
        </div>
      </div>

      <div className="all-property-sec">
        <div className="container-fluid my-container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <div className="sidebar-listing">
                <div className="specialities-checkbox">
                  {/* <div className="searcher-sec">
                    <h4>Find your home</h4>
                    <div className="form-group">
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="What are you looking for?"
                        value={queryParams.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                      <img src={search} />
                    </div>
                  </div> */}
                </div>
                <div className="specialities-checkbox">
                  <div className="listing-check">
                    <h4>Property Category</h4>
                    <div className="custom-checkbox custom-control">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck01"
                        checked={allSelected?.house_facility}
                        onChange={() => {
                          handleChange("house_facility", "");
                        }}
                      />
                      <label className="custom-control-label">All</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck01"
                        checked={
                          queryParams["house_facility"] === "fully furnished" ||
                          allSelected?.house_facility
                        }
                        onChange={(e) => {
                          console.log(
                            e.target.checked,
                            queryParams["house_facility"]
                          );
                          if (e.target.checked) {
                            handleChange("house_facility", "fully furnished");
                          } else {
                            allSelected?.house_facility
                              ? handleChange("house_facility", "customize")
                              : handleChange("house_facility", "");
                          }
                        }}
                      />
                      <label className="custom-control-label">
                        Fully Furnished
                      </label>
                    </div>
                    <div className="custom-checkbox custom-control">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck02"
                        checked={
                          queryParams["house_facility"] === "customize" ||
                          allSelected?.house_facility
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleChange("house_facility", "customize");
                          } else {
                            allSelected?.house_facility
                              ? handleChange(
                                  "house_facility",
                                  "fully furnished"
                                )
                              : handleChange("house_facility", "");
                          }
                        }}
                      />
                      <label className="custom-control-label">
                        Customizable
                      </label>
                    </div>
                  </div>
                  < PropertyTypeFilter
                    queryParams={queryParams}
                    handleChange={handleChange}

                  >
                  </PropertyTypeFilter>
                  <div className="listing-check">
                    <h4>Price Range</h4>
                    {/* <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="5"
                      id="customRange2"
                    /> */}
                    <div className="custom-price">
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="$0"
                        value={queryParams.minprice}
                        onChange={(e) =>
                          handleChange("minprice", e.target.value)
                        }
                      />
                      <i className="fa fa-minus"></i>
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="$50000"
                        value={queryParams.maxprice}
                        onChange={(e) =>
                          handleChange("maxprice", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="listing-check">
                    <h4>Bedroom</h4>
                    <div className="activity-sec date-slot">
                      <ul>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            onChange={() => handleChange("bedroom_no", "")}
                            name="bedroom_no"
                          />
                          <a className="">Any</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bedroom_no"
                            checked={queryParams.bedroom_no === "1"}
                            onChange={() => handleChange("bedroom_no", 1)}
                          />
                          <a className="">1</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bedroom_no"
                            checked={queryParams.bedroom_no === "2"}
                            onChange={() => handleChange("bedroom_no", 2)}
                          />
                          <a className="">2</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bedroom_no"
                            checked={queryParams.bedroom_no === "3"}
                            onChange={() => handleChange("bedroom_no", 3)}
                          />
                          <a className="">3</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bedroom_no"
                            checked={queryParams.bedroom_no === "4"}
                            onChange={() => handleChange("bedroom_no", 4)}
                          />
                          <a className="">4+</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="listing-check">
                    <h4>Bathroom</h4>
                    <div className="activity-sec date-slot">
                      <ul>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            onChange={() => handleChange("bathroom_no", "")}
                          />
                          <a className="">Any</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            checked={queryParams.bathroom_no === "1"}
                            name="bathroom_no"
                            onChange={() => handleChange("bathroom_no", 1)}
                          />
                          <a className="">1</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            checked={queryParams.bathroom_no === "2"}
                            onChange={() => handleChange("bathroom_no", 2)}
                          />
                          <a className="">2</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            checked={queryParams?.bathroom_no === "3"}
                            onChange={() => handleChange("bathroom_no", 3)}
                          />
                          <a className="">3</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            checked={queryParams?.bathroom_no === "4"}
                            onChange={() => handleChange("bathroom_no", 4)}
                          />
                          <a className="">4+</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="listing-check">
                    <h4>Square Feet</h4>
                    {/* <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="5"
                      id="customRange2"
                      onChange={(e) =>
                        handleChange("bathroom_no", e.target.value)
                      }
                    /> */}
                    <div className="custom-price">
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Min."
                        value={queryParams?.minarea}
                        onChange={(e) =>
                          handleChange("minarea", e.target.value)
                        }
                      />
                      <i className="fa fa-minus"></i>
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Max"
                        value={queryParams?.maxarea}
                        onChange={(e) =>
                          handleChange("maxarea", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 loader-position">
              <div className="filter-sec">
                {houseData && houseData?.length > 0 ? (
                  <p>
                    Showing {(queryParams.page_no - 1) * itemsPerPage + 1}–
                    {Math.min(queryParams.page_no * itemsPerPage, totalItems)}{" "}
                    of {totalItems} results
                  </p>
                ) : (
                  <p></p>
                )}

                <div className="sort-sec">
                  <span>Order :</span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setOrder(e.target.value);
                      sortKey(sortVal, e.target.value);
                    }}
                  >
                    <option value="" selected>
                      -
                    </option>
                    <option value="Desc">High to Low</option>
                    <option value="Asc">Low to High</option>
                  </select>
                </div>
                <div className="sort-sec">
                  <span>Sort by :</span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSortVal(e.target.value);
                      sortKey(e.target.value, order);
                    }}
                  >
                    <option value="" selected>
                      -
                    </option>
                    <option value="price">Price</option>
                    <option value="area">Area</option>
                    <option value="bed">Bedroom</option>
                    <option value="bath">Bathroom</option>
                  </select>
                </div>
              </div>
              <div className="row p-20 loader-box my-20">
                {isLoader ? (
                  <div className="p-5 eye-loader-frame">
                    <div className="eye-loader-spinner"></div>
                  </div>
                ) : (
                  <>
                    {houseData?.length ? (
                      houseData.map((house, key) => {
                        return (
                          <div
                            key={key}
                            className="col-lg-4 col-md-4 col-sm-4 col-xl-4 col-xs-12 col-xxl-4"
                          >
                            <div className="property-listing">
                              <div className="property-sec">
                                <div className="property-inner">
                                  <span className="type-pro">
                                    {house.house_facility}
                                  </span>
                                  <Link to={`/property_detail/${house.id}`}>
                                    <img
                                      src={
                                        import.meta.env.VITE_APP_BASE_URL +
                                        house.images[house.images.length - 1]
                                      }
                                      onError={(e) => {
                                        e.target.src = HouseDummyImg;
                                      }}
                                    />
                                  </Link>
                                  <div className="property-content">
                                    <div className="property-price">
                                      <p className="pricing">
                                        ${house.monthly_price}/<sub>Month</sub>
                                      </p>

                                      {/* {isLoggedIn && (
                                        <a onClick={() => addFav(house.id)}>
                                          {/* Use a ternary operator to conditionally render the heart icon 
                                          {house.isFavorite ? (
                                            <img
                                              className="prop-Heart"
                                              style={{ height: "30px" }}
                                              src={redheart}
                                            />
                                          ) : (
                                            <img
                                              className="prop-Heart"
                                              src={
                                                house.isFavorite
                                                  ? redheart
                                                  : heart
                                              }
                                            />
                                          )}
                                        </a>
                                      )} */}
                                      <a
                                        className={`like-sec ${
                                          isFavorite[house.id] ? "like" : ""
                                        }`}
                                        onClick={() => {
                                          if (isLoggedIn) {
                                            addFav(house.id);
                                          } else {
                                            navigate("/login");
                                          }
                                        }}
                                      >
                                        <i className="fa fa-heart-o"></i>
                                      </a>
                                    </div>
                                    <div className="property-discription">
                                      <a>
                                        <h4>{house.name}</h4>
                                        <p>{`${house.full_address} ${house.state} ${house.country} `}</p>
                                      </a>
                                    </div>
                                    <div className="property-overview">
                                      <ul>
                                        <li>
                                          <img src={bed} /> {house.bedroom_no}{" "}
                                          Bedroom
                                        </li>
                                        <li>
                                          <img src={bathroom} />{" "}
                                          {house.bathroom_no} Bathroom
                                        </li>
                                        <li>
                                          <img src={ruler} /> {house.area} SQ FT
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="container">
                        <div className="d-flex row align-items-center h-100 justify-content-center p-20 my-20">
                          No data found
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {houseData?.length > 0 && (
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <CustomPagination
                  totalItems={+totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={pageNo}
                  onPageChange={(page) => setPageNo(page)}
                  disabledPage={isLoader}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertySearch;
