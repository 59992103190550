import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import colorNamer from "color-namer";
import CpBrookImg from "../../../assets/images/cpbrook.png";
import FurnishedSvg from "../../../assets/images/furnished.svg";
import BuildingSvg from "../../../assets/images/Building.svg";
import WaterGlassSvg from "../../../assets/images/Water_Glass.svg";
import CarSvg from "../../../assets/images/car.svg";
import LockAccessSvg from "../../../assets/images/lock_access.svg";
import DirectionToolSvg from "../../../assets/images/direction_tool.svg";
import CpDeluxImg from "../../../assets/images/cpdelux.png";
import BookItem1Img from "../../../assets/images/bookitem1.png";
import BookItem2Img from "../../../assets/images/bookitem2.png";
import BookItem3Img from "../../../assets/images/bookitem3.png";
import BookItem4Img from "../../../assets/images/bookitem4.png";
import BookItem5Img from "../../../assets/images/bookitem5.png";
import BookItem6Img from "../../../assets/images/bookitem6.png";
import CpCleanImg from "../../../assets/images/cpclean.png";
import HouseCleanImg from "../../../assets/images/houseclean.png";
import FurnitClinImg from "../../../assets/images/furnitclin.png";
import CarpetClinImg from "../../../assets/images/carpetclin.png";
import histhomeimg from "../../../assets/images/houseDummy.webp";
import { useUserState, useUserDispatch } from "../../../Contexts/UserContext";

import "./CurrentPlan.css";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { reverse } from "color-namer/lib/colors/basic";
import NegotiationModal from "../../negotationModal/NegotiationModal";

const CurrentPlan = () => {
  const { user, isLoggedIn } = useUserState();
  const navigate = useNavigate();
  const [myCurrentPlan, setMyCurrentPlan] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    if (user?.token && myCurrentPlan.length === 0) {
      getCurrentPlan();
    }
  }, [user?.token]);
  const headers = {
    Authorization: user?.token,
  };
  const getCurrentPlan = async () => {
    const res = await axios.get(
      `${
        import.meta.env.VITE_APP_BASE_URL
      }/api/transactions/get-by-user-tenant?status=current`,
      {
        headers: headers,
      }
    );
    setIsLoader(false);
    if (res) {
      let reversedData = res.data?.data;
      // console.log("res.data?.data-------------------->", res.data?.data)
      setMyCurrentPlan(reversedData);
      setIsLoader(false);
    } else {
      setIsLoader(false);

      alert("error");
    }
  };
  const imgPath = import.meta.env.VITE_APP_BASE_URL;
  const handleError = (e) => {
    e.target.src = histhomeimg; // Fallback to the dummy image on error
  };
  // let abc = myCurrentPlan?;
  // console.log("myCurrentPlan",myCurrentPlan)
  return (
    <Fragment>
      <div className="main-box main_box_update">
        <div className="content-header">
          <h3>My Current Booking</h3>
          {/* <div className="current_plan_head_btn">
         
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              Cancel Booking
            </button>

            <div
              className="modal fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabe1"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="cancel_cont">
                      <div className="cancel_head">
                        <h2>Why do you want to cancel booking ?</h2>
                      </div>
                      <div className="cancel_cont_inner">
                        <div className="cancel_item">
                          <h4>Incorrect Address</h4>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                          </div>
                        </div>
                        <div className="cancel_item">
                          <h4>The offer price is too low</h4>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                          </div>
                        </div>
                        <div className="cancel_item">
                          <h4>House price incorrect</h4>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                          </div>
                        </div>
                        <div className="cancel_item">
                          <h4>User is fake</h4>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                          </div>
                        </div>
                        <div className="cancel_item">
                          <h4>Another reason</h4>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                          </div>
                        </div>
                        <div className=" cancel_item_field">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Write your Reason
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter your reason here..."
                          />
                        </div>
                        <div className="cancel_cost">
                          <h4>Cancel Booking Penalty Cost :</h4>
                          <span>
                            $100<button type="button">Pay</button>
                          </span>
                        </div>
                        <div className="send_offer_btn cancle_book">
                          <button type="button">Done</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
            >
              Plan Extend
            </button>

            <div
              className="modal fade"
              id="exampleModal2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <div className="cancel_cont">
                      <div className="cancel_head">
                        <h2>Do you want to Extend your booking ?</h2>
                      </div>
                      <div className="cancel_cont_inner">
                        <div className="choose_date">
                          <h4>Choose Date</h4>
                          <div className="choose_field">
                            <div className="mb-3 choose_field_inner">
                              <label
                                for="exampleFormControlInput1"
                                className="form-label"
                              >
                                To
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Date"
                              />
                              <span style={{ color: "red" }}>
                                select Start Date
                              </span>
                            </div>
                            <div className="mb-3 choose_field_inner">
                              <label
                                for="exampleFormControlInput1"
                                className="form-label"
                              >
                                From
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Date"
                              />
                              <span style={{ color: "red" }}>
                                select From Date
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="send_offer_btn cancle_book">
                          <button type="button">Done</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="cp_cont_main">
              {myCurrentPlan &&
                myCurrentPlan?.map((ele, index) => {
                  const dta = ele?.order.house_data.amenities;
                  const amenitiesArray = dta.split(",");
                  return (
                    <>
                      <div className="cp_main_head1">
                        <div
                          key={index}
                          className="cp_cont_inner"
                          // onClick={() => navigate(`/property_detail/${ele?.order?.house_id}`)}
                        >
                          {/* {index + 1} */}
                          <div className="cp_cont_left">
                            <img
                              src={`${imgPath}${ele.order.house_data.houseImages[0].imageUrl} `}
                              alt="cpbrook"
                              onError={handleError}
                            />
                          </div>
                          <div className="cp_cont_right">
                            <div className="cp_inner">
                              <div className="house_drop">
                                <h4>{ele?.order.house_data.name}</h4>
                              </div>
                              <p>
                                {/* {ele?.order.house_data.full_address} ,
                              {ele?.order.house_data.state} ,
                              {ele?.order.house_data.country} ,
                              {ele?.order.house_data.zip_code}
                               */}

                                {[
                                  ele?.order.house_data.full_address,
                                  ele?.order.house_data.state,
                                  ele?.order.house_data.country,
                                  ele?.order.house_data.zip_code,
                                ]
                                  .map((item) => item?.replaceAll(";", ","))
                                  .join(",")}
                              </p>
                              <div className="locat_head">
                                <h4>
                                  Starting Month :{" "}
                                  {new Date(
                                    ele?.order.lease_start_date
                                  ).toDateString()}{" "}
                                  to{" "}
                                  {new Date(
                                    ele?.order.lease_end_date
                                  ).toDateString()}
                                </h4>
                                <div className="d-flex align-items-center">
                                  <div className="dir_btn">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        navigate(
                                          `/property_detail/${ele?.order?.house_id}`
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                      >
                                        <path
                                          d="M10.5 11.7513C12.0077 11.7513 13.23 10.529 13.23 9.02125C13.23 7.51352 12.0077 6.29125 10.5 6.29125C8.99225 6.29125 7.76999 7.51352 7.76999 9.02125C7.76999 10.529 8.99225 11.7513 10.5 11.7513Z"
                                          stroke="white"
                                          strokeWidth="1.5"
                                        />
                                        <path
                                          d="M3.16749 7.42875C4.89124 -0.148748 16.1175 -0.139998 17.8325 7.4375C18.8387 11.8825 16.0737 15.645 13.65 17.9725C11.8912 19.67 9.10874 19.67 7.34124 17.9725C4.92624 15.645 2.16124 11.8738 3.16749 7.42875Z"
                                          stroke="white"
                                          strokeWidth="1.5"
                                        />
                                      </svg>{" "}
                                      Get Direction
                                    </button>
                                  </div>

                                  <NegotiationModal
                                    data={{
                                      house_id: ele?.order.house_data.id,
                                      lease_start_date:
                                        ele?.order.lease_start_date,
                                      lease_end_date: ele?.order.lease_end_date,
                                      disabledDates: ele?.booking_data,
                                      order_id: ele?.order_id,
                                      current_lease: ele?.order?.house_price,
                                      house_lease:
                                        ele?.order.house_data.monthly_price,
                                    }}
                                  />
                                </div>
                              </div>
                              <hr />
                              <h4>Overview</h4>
                              <ul className="palm_step">
                                {amenitiesArray?.map((ele, i) => (
                                  <li key={i}>
                                    <img src={FurnishedSvg} alt="furnished" />
                                    <h4>{ele === "Lift" ? "Elevator" : ele}</h4>
                                  </li>
                                ))}
                                {/* <li>
                              <img src={BuildingSvg} alt="furnished" />
                              <h4>1st floor</h4>
                            </li>
                            <li>
                              <img src={WaterGlassSvg} alt="furnished" />
                              <h4>24 Hrs water supply</h4>
                            </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {ele?.order.furnitureCarts &&
                          ele.order.furnitureCarts.length > 0 && (
                            <>
                              <h5>Furniture Details</h5>
                              <div className="cp_cont_inner">
                                {/* <div className="cp_cont_left">
                          <img src={CpDeluxImg} alt="cpbrook" />
                        </div> */}
                                <div className="cp_cont_right">
                                  <div className="cp_inner">
                                    <div className="delux_head">
                                      <h4>
                                        {
                                          ele?.order.furnitureCarts[0]
                                            ?.furniture_category_data.name
                                        }
                                      </h4>
                                      {/* <h4>
                                Delivery Status :
                                <svg style={{position:"relative",top:"-2px",left:"3px"}}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M11.04 6.5925L7.8225 9.8175L6.585 8.58C6.51777 8.50149 6.43503 8.43772 6.34198 8.39271C6.24893 8.34769 6.14758 8.32239 6.04429 8.3184C5.941 8.31441 5.83801 8.33182 5.74176 8.36952C5.64552 8.40723 5.55811 8.46442 5.48502 8.53751C5.41192 8.6106 5.35473 8.69801 5.31703 8.79426C5.27932 8.8905 5.26192 8.9935 5.2659 9.09679C5.26989 9.20008 5.29519 9.30143 5.34021 9.39448C5.38523 9.48753 5.44899 9.57026 5.5275 9.6375L7.29 11.4075C7.36008 11.477 7.4432 11.532 7.53457 11.5693C7.62595 11.6067 7.7238 11.6256 7.8225 11.625C8.01926 11.6242 8.20781 11.5461 8.3475 11.4075L12.0975 7.6575C12.1678 7.58778 12.2236 7.50483 12.2617 7.41343C12.2998 7.32204 12.3194 7.22401 12.3194 7.125C12.3194 7.02599 12.2998 6.92796 12.2617 6.83657C12.2236 6.74517 12.1678 6.66222 12.0975 6.5925C11.957 6.45281 11.7669 6.37441 11.5688 6.37441C11.3706 6.37441 11.1805 6.45281 11.04 6.5925ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98492 1.5 9 1.5ZM9 15C7.81332 15 6.65328 14.6481 5.66658 13.9888C4.67989 13.3295 3.91085 12.3925 3.45673 11.2961C3.0026 10.1997 2.88378 8.99334 3.11529 7.82946C3.3468 6.66557 3.91825 5.59647 4.75736 4.75736C5.59648 3.91824 6.66558 3.3468 7.82946 3.11529C8.99335 2.88378 10.1997 3.0026 11.2961 3.45672C12.3925 3.91085 13.3295 4.67988 13.9888 5.66658C14.6481 6.65327 15 7.81331 15 9C15 10.5913 14.3679 12.1174 13.2426 13.2426C12.1174 14.3679 10.5913 15 9 15Z"
                                    fill="#008000"
                                  />
                                </svg>{" "}
                               <span className="text-success"> Delivered, May 1, 2023</span>
                              </h4> */}
                                    </div>
                                    {}
                                    <div className="furnit_item_detail">
                                      {ele?.order.furnitureCarts?.map(
                                        (furniture) => {
                                          const color =
                                            furniture?.furniture_data?.color ||
                                            "";
                                          let colorName;
                                          const isValidColor =
                                            /^#[0-9A-F]{6}$/i.test(color);

                                          if (isValidColor) {
                                            const colorNameObject =
                                              colorNamer(color)?.ntc?.[0];
                                            colorName =
                                              colorNameObject?.name || "";
                                          }
                                          return (
                                            <>
                                              <div className="delux_furnit_item">
                                                <div className="delux_item">
                                                  <div className="book_item_img">
                                                    <img
                                                      src={`${imgPath}${furniture?.furniture_data?.furniture_images[0]?.imageUrl} `}
                                                      alt=""
                                                      onError={(e) => {
                                                        e.target.src =
                                                          FurnitClinImg;
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="zunkel_cont">
                                                    <h4>
                                                      {
                                                        furniture
                                                          ?.furniture_data?.name
                                                      }
                                                    </h4>
                                                    <p>
                                                      <span className="br_item">
                                                        {furniture?.quantity}{" "}
                                                        item
                                                      </span>{" "}
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            `${color}` ||
                                                            "#000",
                                                          width: "20px",
                                                          height: "20px",
                                                          borderRadius: "2px",
                                                        }}
                                                      ></div>
                                                      <span>
                                                        {colorName
                                                          ? colorName
                                                          : "black"}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        {ele?.order.furnitureCarts &&
                          ele.order.furnitureCarts.length > 0 && <hr />}
                        {/* <hr /> */}
                        <h5>Cleaning Details</h5>
                        <div className="cleaning">
                          {ele?.order.cleaningServiceCarts?.map((cleaning) => {
                            return (
                              <>
                                <div className="row cleaning_detail">
                                  <h>
                                    {cleaning?.cleaning_category_data?.name}
                                  </h>
                                  {/* <div className="col-md-6">
                              <p><b>price:</b>{cleaning?.cleaning_category_data?.price}</p>
                            </div> */}
                                  <div className="col-md-6">
                                    <p>
                                      <b>Frequency:</b>
                                      {cleaning?.frequency}
                                    </p>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                        {ele?.order.cleaningServiceCarts &&
                          ele.order.cleaningServiceCarts.length > 0 && <hr />}
                        {/* <hr /> */}
                        {user?.currentRole === "relo" &&
                          !!ele?.order?.tenant_list.length && (
                            <>
                              <h5>Tenant Details</h5>
                              <div className="tenant_list_table">
                                <div className="row tenant_detail">
                                  <h6>Tenant Name</h6>
                                  <div className="col-md-6">
                                    <p>Contact</p>
                                    <p>Age</p>
                                  </div>
                                </div>

                                {ele?.order?.tenant_list?.map((tenant) => (
                                  <div
                                    key={tenant?.id}
                                    className="row tenant_detail"
                                  >
                                    <h6>{tenant?.name || " "}</h6>
                                    <div className="col-md-6">
                                      <p>{tenant?.phone || "_\u00A0"}</p>
                                      <p>
                                        {tenant?.age ? tenant.age : "_\u00A0"}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}

                        {/* <div className="cp_main_head"></div> */}
                      </div>
                    </>
                  );
                })}

              {/* <div className="loading-data">
                {isLoader ?
                  <Spinner></Spinner>
                  : "There is no Booking."}
              </div> */}

              {isLoader && (
                <div className="eye-loader-frame m-5 p-5">
                  <div className="eye-loader-spinner"></div>
                </div>
              )}

              {
                myCurrentPlan.length == 0 && !isLoader && (
                  <div className="eye-loader-frame m-5 p-5">
                    There is no Booking.
                    {/* <div className="eye-loader-spinner"></div> */}
                  </div>
                )

                // <div className="loading-data">
                //   There is no Booking.
                // </div>
              }
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CurrentPlan;
