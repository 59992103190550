import React, { Fragment, useEffect, useState, useContext } from "react";

import { Spinner, Modal, Button } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

import { useUserState, UserContext } from "../../../Contexts/UserContext";

import MyCard from "./MyCard";

import PlusSvg from "../../../assets/images/plus.svg";
import PayapproveImg from "../../../assets/images/payapprove.png";
import PayrightImg from "../../../assets/images/payright.png";

const MyBillingDetail = () => {
  const stripePromise = loadStripe("pk_test_Y680iCV5awEdWNzwLhXCVq76");
  const { user } = useUserState();
  const { addCardModal, setAddCardModal } = useContext(UserContext);
  const [token, setToken] = useState("");
  const [title, setTitle] = useState("Add Payment Method");
  const [cardData, setCardData] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [isCard, setIsCard] = useState(false);
  const [delCardModal, setDelCardModal] = useState(false);
  const [delCardDetail, setDelCardDetail] = useState({
    payment_method_id: "",
    stripe_customer_id: "",
    index: "",
  });
  const [loader, setLoader] = useState(false);
  const [transactionList, setTransactionList] = useState([]);

  

  useEffect(() => {
    setToken(user?.token);
    if (user?.token) {
      getPaymentDetails();
    }
  }, [user?.token]);

  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };

  const getPaymentDetails = async () => {
    setIsLoader(true);
    setCardData([]);
    const res = await axios.get(
      `${import.meta.env.VITE_APP_BASE_URL}/api/payment-method/get-list`,
      { headers: headerJson }
    );
    setIsLoader(false);
    if (res) {
      setCardData(res?.data?.data?.data);
    }
  };

  const deleteCard = async () => {
    const { payment_method_id, stripe_customer_id, index } = delCardDetail;
    setLoader(true);
    let data = {
      payment_method_id: payment_method_id,
      stripe_customer_id: stripe_customer_id,
    };
    const res = await axios.delete(
      `${import.meta.env.VITE_APP_BASE_URL}/api/payment-method/detach`,
      { headers: headerJson, data: data }
    );
    if (res) {
      setLoader(false);
      setDelCardModal(false);
      let tmpCardData = [...cardData];
      tmpCardData.splice(index, 1);
      setCardData(tmpCardData);
      // getPaymentDetails();
    }
  };

  const getTransactionList = async () => {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_APP_BASE_URL}/api/transactions/get-trnx-list`,
        { headers: headerJson }
      );
      setTransactionList(res?.data?.data);
    } catch (err) {
      console.log(
        "Error while hitting get transaction history API",
        err?.message
      );
    }
  };

  useEffect(() => {
    getTransactionList();
  }, [user?.token]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCard(false);
    }, 2000); // Set the duration in milliseconds (1 second in this case)

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount or re-render
  }, [isCard]);

  return (
    <Fragment>
      <div className="main-box billing_det_sec">
        <div className="content-header bill_add_new_card">
          <h3>My Billing Details</h3>
          <button
            type="button"
            className="btn btn-primary add_card"
            onClick={() => setAddCardModal(true)}
          >
            <img src={PlusSvg} alt="plus" /> Add New Card
          </button>
        </div>

        <div className="bill_cont_main">
          <div className="bill_cont_left">
            <h4>My Cards</h4>
            <div className="bill_card_main">
              {cardData.length > 0 &&
                cardData.map((card, i) => (
                  <div className="payment" key={i}>
                    <div className="payment-image">
                      <img
                        src={
                          card.card.brand === "visa"
                            ? "https://2.bp.blogspot.com/-E85z2lvyugo/Wxqgevf3MLI/AAAAAAAAA5Y/vfTQDWg8g9Ii--s6KhDC0yWb9_h7l47dwCLcBGAs/s1600/visa.png"
                            : "https://1.bp.blogspot.com/-b_7SEUzJd4M/Wxqgerdr6xI/AAAAAAAAA5c/7b1znN3spZsGJUn7DlQh72hzbQoqmYEtACLcBGAs/s1600/mastercard.png"
                        }
                        alt="visa"
                      />
                    </div>
                    <label className="payment-label is-visa" for="payment-visa">
                      <div className="card-name">
                        {card?.billing_details?.name}
                        <span>**** **** **** {card?.card?.last4}</span>
                      </div>
                      <div className="card-exp">
                        EXP{" "}
                        <span>
                          {card?.card?.exp_month}/{card?.card?.exp_year}
                        </span>
                      </div>
                    </label>
                    <div
                      className="delete"
                      onClick={() => {
                        setDelCardModal(true);
                        setDelCardDetail({
                          payment_method_id: card?.id,
                          stripe_customer_id: card?.customer,
                          index: i,
                        });
                      }}
                    >
                      <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </div>
                  </div>
                ))}

              {isLoader && (
                <div className="eye-loader-frame m-5 p-5">
                  <div className="eye-loader-spinner"></div>
                </div>
              )}

              {cardData.length == 0 && !isLoader && (
                <div className="loading-data">There is no card.</div>
              )}
            </div>
          </div>
          <div className="bill_cont_right">
            <h4>My Transactions History</h4>
            <div className="transt_hist_main">
              {transactionList?.length > 0
                ? [("tenant", "relo")].includes(user?.currentRole) &&
                  transactionList?.map((ele) => {
                    return (
                      <div className="receive_pay" key={ele?.id}>
                        <div className="transt_hist_inner">
                          <div className="payapprove_img">
                            <img src={PayapproveImg} alt="Approve" />
                          </div>
                          <div className="pay_head">
                            <h4>
                              Payment{" "}
                              {ele?.status === "succeeded"
                                ? "done"
                                : "Incomplete"}
                            </h4>
                            <p>
                              {new Date(ele?.created_at).toLocaleString(
                                "en-US"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="doler_num">
                          <h2>${ele?.amount / 100}</h2>
                          <img src={PayrightImg} alt="" />
                        </div>
                      </div>
                    );
                  })
                : "There is no Transaction history."}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={delCardModal}
        onHide={() => setDelCardModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this card?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDelCardModal(false)}>
            No
          </Button>
          <Button
            variant="primary"
            disabled={loader}
            onClick={() => deleteCard()}
          >
            Yes {loader && <Spinner />}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addCardModal}
        onHide={() => setAddCardModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        className="card-payment"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Elements stripe={stripePromise}>
            <MyCard
              token={token}
              setTitle={setTitle}
              getPaymentDetails={getPaymentDetails}
              setIsCard={setIsCard}
            />
          </Elements>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default MyBillingDetail;
