import { Fragment, useState } from "react";

import WhyChooseImg from "../../../assets/images/why-choose.png";
import VisitImg from "../../../assets/images/visit.png";
import HousePlanImg from "../../../assets/images/house-plan.png";
import PeaceImg from "../../../assets/images/peace.png";
import AmenitiesImg from "../../../assets/images/amenities.png";

import "./WhyChooseStyle.css";

const WhyChoose = () => {
  const [isContentExpanded, setIsContentExpanded] = useState({
    WhyChooseImg: false,
    VisitImg: false,
    HousePlanImg: false,
    PeaceImg: false,
    AmenitiesImg: false,
  });
  const toggleContent = (section) => {
    setIsContentExpanded((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const getReadMoreText = (section) => {
    return (isContentExpanded[section] ? "Read Less" : "Read More").replace(
      /(Read More|Read Less)/,
      (match) => match
    );
  };

  return (
    <Fragment>
      <div className="why-choose-us">
        <div className="container-fluid my-container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="heading-sec">
                <img src={WhyChooseImg} />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="heading-sec inner">
                <h2>
                  Why Choose<span> Druyp.</span>
                </h2>
                <p>
                  Freedom, independence, and liberty are priceless. Druyp
                  empowers you to make choices that suit your lifestyle. With
                  Druyp, you decide how much you want to pay, where you want to
                  live, and how you want to live. You can negotiate directly
                  with the property owner, select your preferred location,
                  choose the rental terms that fit your needs, pick the
                  furnishings you desire, and even choose the type of cleaning
                  and cleaner that works best for you. The power of choice is
                  with you, from start to finish
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="whychoose-sec">
                    <img src={VisitImg} />
                    <h2>Visit Physically</h2>
                    <p>
                      Our services are primarily online, but if you would like
                      to visit us in person, please contact us to schedule an
                      appointment. Let us know how we can assist you!
                      <br /> Our business operates remotely and offer virtual
                      assistance.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="whychoose-sec">
                    <img src={HousePlanImg} />
                    <h2>Choose House Plan</h2>
                    <p>
                      This option allows tenants to select based on their
                      preferences:
                      <br />
                      <strong>Furnished Units:</strong> Enjoy the convenience
                      and luxury of a fully furnished unit, ready for you to
                      move in.
                      {isContentExpanded.HousePlanImg && (
                        <>
                          <br />
                          <strong>Customized Units:</strong> Experience the
                          freedom of furnishing the unit to match your personal
                          style and taste.
                          <br />
                          This gives tenants the flexibility to choose what
                          suits them best.
                        </>
                      )}
                      <a
                        onClick={() => toggleContent("HousePlanImg")}
                        className="read-more"
                      >
                        <br />
                        {getReadMoreText("HousePlanImg")}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="whychoose-sec">
                    <img src={PeaceImg} />
                    <h2>Live In Peace</h2>
                    <p>
                      True peace comes when you have control over how you live.
                      Peace is found in the power of choice. Choose how you
                      live. Choose your peace.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="whychoose-sec">
                    <img src={AmenitiesImg} />
                    <h2>Amenities</h2>
                    <p>
                      <strong> Amenities Pricing:</strong>
                      The pricing is structured in two ways to provide
                      flexibility. In both cases, utilities are included in the
                      rent to ensure an easy transition for tenants moving in
                      and out of the rental unit.
                      {isContentExpanded.AmenitiesImg && (
                        <>
                          <br />
                          <strong> Rent with Utilities Included:</strong> In
                          this option, utilities are already factored into the
                          rent, so the tenant pays a single amount for both rent
                          and utilities.
                          <br />{" "}
                          <strong>
                            {" "}
                            Rent without Utilities Included:
                          </strong>{" "}
                          Here, the owner will estimate the average monthly
                          utility usage, and that amount will be added to the
                          rent during payment. This gives tenants a clear choice
                          based on their preferences
                        </>
                      )}
                      <a
                        onClick={() => toggleContent("AmenitiesImg")}
                        className="read-more"
                      >
                        <br />
                        {getReadMoreText("AmenitiesImg")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WhyChoose;
