import img404 from "./images/404-image.png"

const NotFoundPage = () => {
  return (
    <>
      <head>
        <title>Druyp</title>
        <link rel="icon" href="../../../src/assets/images/favicon.ico" />
      </head>
      <div className="page-not-found">
        <div className="container-fluid">
          <div className="page-interface text-center">
            <img src={img404} alt="404 - Page Not Found" />
            <div className="page-detail">
              <h1>404 - PAGE NOT FOUND</h1>
              <div className="about-para">
                <p>
                  The page you are looking for might have been removed, had its
                  name changed, or is temporarily unavailable.
                </p>
              </div>
              <a href="/" className="bg-primary homepagego-button">
                GO TO HOMEPAGE
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
