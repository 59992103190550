import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserAuth } from "./UserAuthContext";
import openEye from "../assets/icons/open-eye.svg";
import closeEye from "../assets/icons/closed-eye.svg";
import mail from "../assets/icons/mail.svg";
import PWD from "../assets/icons/password.svg";
import facebookIcon from "../assets/icons/Facebook.svg";
import googleIcon from "../assets/icons/Google.svg";
import appleIcon from "../assets/icons/Apple.svg";
import { useUserDispatch } from "../Contexts/UserContext";
import Button from "./common/LoadingButton";
import {
  setSessionStorageWithExpiration,
  getSessionStorageWithExpiration,
} from "@utils/sessionStorageHelper";
import { Spinner } from "react-bootstrap";

const Login = () => {
  const prevUser = getSessionStorageWithExpiration("user");
  const prevRememberMe = getSessionStorageWithExpiration("rememberMe");
  const navigate = useNavigate();
  const [email, setEmail] = useState(prevRememberMe ? prevUser.email : "");

  const [password, setPassword] = useState(
    prevRememberMe ? prevUser.password : ""
  );
  const [isPasswordEyeOpen, setIsPasswordEyeOpen] = useState(false);
  const [loginError, setLoginError] = useState("");
  const { googleSignIn, user, facebookSignIn, appleSignIn } = useUserAuth();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [rememberMe, setRememberMe] = useState(prevRememberMe);
  const dispatch = useUserDispatch();

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const handleLogin = (data) => {
    // Simulate a login process and dispatch the LOGIN action with user data
    const user = {
      ...data,
      currentRole: data.role[0],
    };
    dispatch({ type: "LOGIN", payload: user });
    if (rememberMe) {
      setSessionStorageWithExpiration("user", { email, password });
      setSessionStorageWithExpiration("rememberMe", true);
    } else {
      setSessionStorageWithExpiration("user", null);
      setSessionStorageWithExpiration("rememberMe", false);
    }
    const beforeLoginPage = localStorage.getItem("beforeLoginPage");
    if (beforeLoginPage) {
      navigate(beforeLoginPage);
    } else if (data.is_email_verified) {
      navigate(`/user_dash/${user?.currentRole}`);
    } else {
      navigate(`/verify_email`);
    }
  };

  const handleformsubmit = async () => {
    if (!email || !emailRegex.test(email) || !password) {
      setInputError(true);
    } else {
      try {
        setIsButtonLoading(true);
        const response = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/login`,
          {
            email: email,
            password: password,
          }
        );
        handleLogin(response?.data?.data);
        setIsButtonLoading(false);
        setInputError(false);
      } catch (error) {
        setIsButtonLoading(false);
        setLoginError(error?.response?.data?.message);
        console.log("login_error", error);
      }
    }
  };

  const handleFacebookLogin = async (e) => {
    e.preventDefault();
    try {
      await facebookSignIn();
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/login`,
          {
            email: user.email,
            password: "",
            socialLogin: "facebookLogin",
          }
        );

        // Handle the response here, e.g., set user authentication state.
        handleLogin(response.data.data);
        navigate(`/user_dash/${response.data.data.role[0]}`);
      } catch (error) {
        setLoginError("Login failed. Please enter valid email and password.");
        console.error("Login failed", error);
      }
    } catch (err) {
      console.log(err.message);
    }
    // navigate("/dashboard");
  };

  const handleGoogleLogin = async (e) => {
    setLoginError("");
    e.preventDefault();
    try {
      await googleSignIn();
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/login`,
          {
            email: user?.email,
            password: "",
            socialLogin: "googleLogin",
          }
        );

        // Handle the response here, e.g., set user authentication state.
        handleLogin(response.data.data);
        navigate(`/user_dash/${response.data.data.role[0]}`);
      } catch (error) {
        setLoginError("Login failed. Please enter valid email and password.");
        console.error("Login failed", error);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleAppleSignIn = async (e) => {
    e.preventDefault();
    try {
      await appleSignIn();
      window.localStorage.setItem("user", 123);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <section className="druyp_login_sec">
        <div className="container-fluid">
          <div className="row align-items-center min-vh-100">
            <div className="col-lg-6">
              <div className="druyp_login_inner">
                <Link className="druyp_logo " to={`/`}>
                  Druyp{" "}
                </Link>
                <h4>Welcome back</h4>
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3 druyp_mail">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Email
                        </label>
                        <input
                          value={email}
                          autoComplete="off"
                          type="text"
                          className={`form-control ${
                            inputError && (!email || !emailRegex.test(email))
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your email address"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="mail_icon">
                          <img src={mail} alt="mail image" />
                        </div>
                      </div>
                      {inputError && (!email || !emailRegex.test(email)) && (
                        <div className="invalid-feedback">
                          {!email
                            ? "Please enter your email address"
                            : !emailRegex.test(email)
                            ? "Invalid credentials"
                            : ""}
                          Enter a valid email.
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3 druyp_mail">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Password
                        </label>
                        <input
                          value={password}
                          autoComplete="off"
                          type={isPasswordEyeOpen ? "text" : "password"}
                          className={`form-control ${
                            inputError && !password ? "is-invalid" : ""
                          }`}
                          id="exampleInputPassword1"
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="pwd_icon">
                          <img src={PWD} alt="lock image" />
                        </div>
                        <div
                          className="eye_icon"
                          onClick={() =>
                            setIsPasswordEyeOpen(!isPasswordEyeOpen)
                          }
                        >
                          <img
                            src={isPasswordEyeOpen ? openEye : closeEye}
                            alt="open-eye"
                          />
                        </div>
                      </div>
                      {inputError && !password && (
                        <div className="invalid-feedback">
                          Invalid credentials.
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <div className="form-check druyp_check check_acc">
                        <input
                          onChange={() => {
                            setRememberMe((prev) => !prev);
                          }}
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          checked={rememberMe}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="druyp_login_btn">
                        <p className="alert-sec">{loginError}</p>
                        <Button
                          // isLoading={isButtonLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            handleformsubmit({});
                          }}
                          disabled={isButtonLoading}
                        >
                          LOG IN
                          {isButtonLoading ? <Spinner></Spinner> : null}
                        </Button>
                      </div>
                      <Link to="/password_reset">
                        <div className="druyp_fpasword">Forgot Password?</div>
                      </Link>
                      <div className="or_with">Or Login with</div>
                      <div className="social_icon_main">
                        <a
                          className="social_icon"
                          onClick={handleFacebookLogin}
                        >
                          <img src={facebookIcon} alt="Facebook Icon" />
                        </a>
                        <a className="social_icon" onClick={handleGoogleLogin}>
                          <img src={googleIcon} alt="Google Icon" />
                        </a>
                        <a className="social_icon" onClick={handleAppleSignIn}>
                          <img src={appleIcon} alt="Apple icon" />
                        </a>
                      </div>
                      <div className="create_new">
                        New here?{" "}
                        <a
                          onClick={() => {
                            navigate("/Sign_up");
                          }}
                        >
                          <spam
                            
                            className="druyp_fpasword"
                          >
                            Create an account
                          </spam>
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;


