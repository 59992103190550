import userPic from "../assets/images/user.png";
import maccount from "../assets/images/maccount.svg";
import reload from "../assets/images/reload file.svg";
import approve from "../assets/images/approve file.svg";
import creditCard from "../assets/images/credit card.svg";
import shieldCheck from "../assets/images/shield check.svg";
import Bell from "../assets/images/Bell.svg";
import contact from "../assets/images/contact.svg";
import House from "../assets/images/House.svg";
import houses from "../assets/images/houses.svg";
import Logout from "../assets/images/Logout.svg";
import brush from "../assets/images/brush.svg";
import heart from "../assets/images/heart.svg";
import DoubleBed from "../assets/images/Double Bed.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import NegoIcon from "../assets/images/negoicon.svg";

import FooterDash from "./FooterDash";

import { useState, useEffect } from "react";
const links = {
  tenant: [
    {
      text: "My Account",
      imgSrc: maccount,
      to: "",
    },
    {
      text: "Favorite Houses ",
      imgSrc: heart,
      to: "favorite-houses",
    },
    {
      text: "My History",
      imgSrc: reload,
      to: "history",
    },
    {
      text: "My Current Booking",
      imgSrc: approve,
      to: "current-plan",
    },
    {
      text: "Negotiations",
      imgSrc: NegoIcon,
      to: "negotiations",
    },
    {
      text: "Billing Details",
      imgSrc: creditCard,
      to: "billing",
    },
    {
      text: "Login & Security",
      imgSrc: shieldCheck,
      to: "login_security",
    },
    {
      text: "Notifications",
      imgSrc: Bell,
      to: "notifications",
      customClass: "custom-menu",
    },
    {
      text: "Contact",
      imgSrc: contact,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Customized Homes",
      imgSrc: houses,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Logout",
      imgSrc: Logout,
      to: "/",
    },
  ],
  home_owner: [
    {
      text: "My Account",
      imgSrc: maccount,
      to: "",
    },
    {
      text: "My Houses",
      imgSrc: House,
      to: "my_houses",
    },
    // {
    //   text: "Favorite Houses ",
    //   imgSrc: heart,
    //   to: "",
    // },
    {
      text: "Billing Details",
      imgSrc: creditCard,
      to: "billing",
    },
    {
      text: "Negotiations",
      imgSrc: NegoIcon,
      to: "negotiations",
    },
    {
      text: "Login & Security",
      imgSrc: shieldCheck,
      to: "login_security",
    },
    {
      text: "Notifications",
      imgSrc: Bell,
      to: "notifications",
      customClass: "custom-menu",
    },
    {
      text: "Contact",
      imgSrc: contact,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Customized Homes",
      imgSrc: houses,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "My History",
      imgSrc: reload,
      to: "history",
    },
    {
      text: "Logout",
      imgSrc: Logout,
      to: "/",
    },
  ],
  furniture_company: [
    {
      text: "My Account",
      imgSrc: maccount,
      to: "",
    },
    {
      text: "My Furniture Store",
      imgSrc: reload,
      to: "furniture_store",
    },
    {
      text: "My Booking",
      imgSrc: approve,
      to: "booking_details",
    },
    {
      text: "Billing Details",
      imgSrc: creditCard,
      to: "billing",
    },
    {
      text: "Login & Security",
      imgSrc: shieldCheck,
      to: "login_security",
    },
    {
      text: "Notifications",
      imgSrc: Bell,
      to: "notifications",
      customClass: "custom-menu",
    },
    {
      text: "My History",
      imgSrc: contact,
      to: "history",
      // customClass: "custom-menu-des",
    },
    {
      text: "Contact",
      imgSrc: contact,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Customized Homes",
      imgSrc: houses,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Logout",
      imgSrc: Logout,
      to: "/",
    },
  ],
  cleaning_company: [
    {
      text: "My Account",
      imgSrc: maccount,
      to: "",
    },
    {
      text: "My Cleaning Store",
      imgSrc: reload,
      to: "cleaning_store",
    },
    {
      text: "My Booking",
      imgSrc: approve,
      to: "booking_details",
    },
    {
      text: "Billing Details",
      imgSrc: creditCard,
      to: "billing",
    },
    {
      text: "Login & Security",
      imgSrc: shieldCheck,
      to: "login_security",
    },
    {
      text: "Notifications",
      imgSrc: Bell,
      to: "notifications",
      customClass: "custom-menu",
    },
    {
      text: "My History",
      imgSrc: contact,
      to: "history",
      // customClass: "custom-menu-des",
    },
    {
      text: "Contact",
      imgSrc: contact,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Customized Homes",
      imgSrc: houses,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Logout",
      imgSrc: Logout,
      to: "/",
    },
  ],
  property_manager: [
    {
      text: "My Account",
      imgSrc: maccount,
      to: "/user_dash/property_manager",
    },
    {
      text: "My Houses",
      imgSrc: House,
      to: "my_houses",
    },
    // {
    //   text: "Favorite Houses ",
    //   imgSrc: heart,
    //   to: "",
    // },
    {
      text: "Billing Details",
      imgSrc: creditCard,
      to: "billing",
    },
    {
      text: "Negotiations",
      imgSrc: NegoIcon,
      to: "negotiations",
    },
    {
      text: "Login & Security",
      imgSrc: shieldCheck,
      to: "login_security",
    },
    {
      text: "Notifications",
      imgSrc: Bell,
      to: "notifications",
      customClass: "custom-menu",
    },
    {
      text: "Contact",
      imgSrc: contact,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Customized Homes",
      imgSrc: houses,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "My History",
      imgSrc: reload,
      to: "history",
    },
    {
      text: "Logout",
      imgSrc: Logout,
      to: "/",
    },
  ],
  relo: [
    {
      text: "My Account",
      imgSrc: maccount,
      to: "",
    },
    {
      text: "Favorite Houses ",
      imgSrc: heart,
      to: "favorite-houses",
    },
    {
      text: "My History",
      imgSrc: reload,
      to: "history",
    },
    {
      text: "My Current Booking",
      imgSrc: approve,
      to: "current-plan",
    },
    {
      text: "Negotiations",
      imgSrc: NegoIcon,
      to: "negotiations",
    },
    {
      text: "Billing Details",
      imgSrc: creditCard,
      to: "billing",
    },
    {
      text: "Login & Security",
      imgSrc: shieldCheck,
      to: "login_security",
    },
    {
      text: "Notifications",
      imgSrc: Bell,
      to: "notifications",
      customClass: "custom-menu",
    },
    {
      text: "Contact",
      imgSrc: contact,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Customized Homes",
      imgSrc: houses,
      to: "",
      customClass: "custom-menu-des",
    },
    {
      text: "Logout",
      imgSrc: Logout,
      to: "/",
    },
  ],
};
const loginLinks = [
  {
    text: "Login as Tenant",
    imgSrc: DoubleBed,
    to: "/user_dash/tenant",
    role: "tenant",
  },
  {
    text: "Login as Home Owner",
    imgSrc: houses,
    to: "/user_dash/home_owner",
    role: "home_owner",
  },
  {
    text: "Login as Cleaning Company",
    imgSrc: brush,
    to: "",
    role: "cleaning_company",
  },
  {
    text: "Login as Furniture Company",
    imgSrc: DoubleBed,
    to: "",
    role: "furniture_company",
  },
  {
    text: "Login as Relocation Agent",
    imgSrc: DoubleBed,
    to: "/user_dash/relo",
    role: "relo",
  },
  {
    text: "Login as Property Manager",
    imgSrc: DoubleBed,
    to: "/user_dash/property_manager",
    role: "property_manager",
  },
];

function SideNav() {
  const dispatch = useUserDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoggedIn } = useUserState();
  const roleList = user?.role;
  const [role, setRole] = useState(user?.currentRole || "");

  useEffect(() => {
    // Update the role when user?.currentRole changes
    if (user?.currentRole !== undefined) {
      setRole(user?.currentRole);
    }
  }, [user?.currentRole]);

  const roleLinks = loginLinks
    .filter((link) => roleList?.includes(link.role))
    .filter((link) => link.role !== role);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };
  const returnPath = (path) => {
    if (path) {
      let paths = path.split("/");
      return paths.length == 4 ? paths[3] : paths[2];
    }
  };
  return (
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <nav
          className="sb-sidenav accordion sb-sidenav-dark"
          id="sidenavAccordion"
        >
          <div className="sb-sidenav-menu">
            <div className="nav">
              <div className="profile-pic">
                <img
                  src={user?.profile_img ? user?.profile_img : userPic}
                  onError={(e) => e.target.src = userPic }
                />
              </div>
              <p className="user-name">{user?.name}</p>
              {links[role]?.map((link, index) => (
                <Link
                  style={{ textDecoration: "none" }}
                  key={index}
                  to={link.to}
                  onClick={() => {
                    if (link.text === "Logout") {
                      handleLogout();
                    }
                  }}
                >
                  <a
                    className={`nav-link ${link.customClass || ""} ${
                      returnPath(location?.pathname) == link.to ? "active" : ""
                    }`}
                  >
                    {/* <a className={`nav-link ${link.customClass || ""}`}> */}
                    <div className="sb-nav-link-icon">
                      <img src={link.imgSrc} alt={link.text} />
                    </div>
                    {link.text}
                  </a>
                </Link>
              ))}
              {roleLinks.length > 1 && (
                <div className="dif-portal">
                  <p>Login as</p>
                </div>
              )}
              {/* ... Other links */}
              {roleLinks.map((link, index) => (
                <Link
                  to={link.to}
                  style={{ textDecoration: "none" }}
                  key={index}
                  className="nav-link"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "instant",
                    });
                    const updatedUserRole = {
                      ...user,
                      currentRole: link.role,
                    };
                    // Dispatch an action to update the user in the context
                    dispatch({ type: "UPDATE_USER", payload: updatedUserRole });
                  }}
                >
                  <div className="sb-nav-link-icon">
                    <img src={link.imgSrc} alt={link.text} />
                  </div>
                  {link.text}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </div>
      <div id="layoutSidenav_content">
        <main>
          <div className="container-fluid px-4">
            <div className="row">
              <div className="col-md-12 col-12">
                <Outlet />
              </div>
            </div>
          </div>
        </main>
        <FooterDash />
      </div>
    </div>
  );
}

export default SideNav;
