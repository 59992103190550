import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook for navigation
import Slider from "react-slick";
import Slide1Img from "../../../assets/images/slide1.png";
import Slide2Img from "../../../assets/images/slide2.png";
import Slide3Img from "../../../assets/images/slide3.png";
import LocationSvg from "../../../assets/images/location.svg";
import CalenderSvg from "../../../assets/images/calendar.svg";
import "./HeroBannerStyle.css";
import { DatePicker } from "antd";
import moment from "moment";

const HeroBannerSec = () => {
  const [selectedCategories, setSelectedCategories] = useState({
    all: false,
    fullyFurnished: false,
    customise: false,
  });
  const [location, setLocation] = useState(""); // Track location input
  const [selectedOption, setSelectedOption] = useState("");
  const [date, setDate] = useState(""); // Track date input
  const navigate = useNavigate(); // Hook to navigate to different pages

  const settings = {
    dots: true,
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    if (id === "all") {
      setSelectedCategories({
        all: checked,
        fullyFurnished: checked,
        customise: checked,
      });
    } else {
      setSelectedCategories((prevState) => {
        const updatedState = { ...prevState, [id]: checked };
        // const allChecked = Object.values(updatedState).every((val) => val);
        const bothSelected =
          updatedState.fullyFurnished && updatedState.customise;

        return {
          ...updatedState,
          all: bothSelected,
        };
      });
    }
  };

  const handleSearchClick = () => {
    // Create a new URLSearchParams instance to hold query parameters
    const queryParams = new URLSearchParams();

    // Append only non-empty or non-null fields to the query parameters
    if (location) {
      queryParams.append("searchKey", location);
    }
    if (selectedOption){
      queryParams.append("house_type", selectedOption);
    }
      if (date) {
        queryParams.append("date", date);
      }

    // Append selected categories if they are true
    Object.keys(selectedCategories).forEach((category) => {
      if ( !selectedCategories.all && selectedCategories[category]) {
        if (category === "fullyFurnished" || category === "customise") {
          let categs =
            category === "fullyFurnished" ? "fully furnished" : "customize";
          queryParams.append("house_facility", categs);
        } 
      }
    });
    if (queryParams)
      // Redirect user to the search results page with the constructed query parameters
      navigate(`/properties?${queryParams.toString()}`);
  };
  const handleDateChange = (value) => {
    setDate(value ? value.format("YYYY-MM-DD") : null); // Format the date to 'yyyy-mm-dd'
  };
   const handleDropboxChange = (event) => {
     setSelectedOption(event.target.value);
     console.log(event.target.value); // You can replace this with your desired logic
   };

  return (
    <Fragment>
      <div className="banner-section">
        <div className="search-form">
          <div className="input-sec input-sec1">
            <label htmlFor="location" className="form-label">
              Location/House<span className="star_symb">*</span>
            </label>
            <div className="search_image_icon">
              <img src={LocationSvg} />
              <input
                type="text"
                className="form-control"
                id="location"
                placeholder="Enter your House Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)} // Update location state
              />
            </div>
          </div>
          {/* <div className="input-sec input-sec1">
            <label htmlFor="date" className="form-label">
              Start Date<span className="star_symb">*</span>
            </label>
            <div className="search_image_icon calander-input">
              {/* <img src={CalenderSvg} /> */}
          {/* <input
                type="date"
                className="form-control"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)} // Update date state
                min={
                  new Date(new Date().setDate(new Date().getDate() + 1))
                    .toISOString()
                    .split("T")[0]
                }
              />  // 
              <DatePicker
                id="date"
                className="dpc"
                // value={date ? moment(date, "YYYY-MM-DD") : null} // Format the value for DatePicker
                onChange={handleDateChange} // Handle date change
                disabledDate={(current) =>
                  current.isBefore(moment().add(2, "days"), "day")
                } // Disable past dates and today
                format="YYYY-MM-DD" // Set the date format
                inputReadOnly
              />
            </div>
          </div> */}
          <div className="input-sec input-sec1">
            <label htmlFor="propertyType" className="form-label">
              Select Property Type:{" "}
            </label>
            <div className="search_dropdown-icon-select search_image_icon">
              <select
                id="propertyType"
                className="form-control dpc"
                onChange={handleDropboxChange}
              >
                <option value="">All</option>
                <option value="house">House</option>
                <option value="apartment">Apartments</option>
                <option value="office">Office</option>
                <option value="villa">Villa</option>
              </select>
              <span className="dropdown-icon-select">&#9662;</span>
            </div>
          </div>
          <div className="input-sec input-sec2">
            <label htmlFor="category" className="form-label">
              Choose category<span className="star_symb">*</span>
            </label>
            <div className="choose-sec">
              {/* "All" checkbox */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="all"
                  checked={selectedCategories.all}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="all">
                  All
                </label>
              </div>

              {/* "Fully Furnished" checkbox */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="fullyFurnished"
                  checked={selectedCategories.fullyFurnished}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="fullyFurnished">
                  Fully Furnished
                </label>
              </div>

              {/* "customise" checkbox */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="customise"
                  checked={selectedCategories.customise}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="customise">
                  customise
                </label>
              </div>
            </div>
          </div>
          <div className="input-sec button-sec">
            <button className="handleSearchClick" onClick={handleSearchClick}>
              Search
            </button>{" "}
            {/* Change anchor to button */}
          </div>
        </div>
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <Slider {...settings}>
            <div className="carousel-item active">
              <img src={Slide1Img} alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Slide2Img} alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Slide3Img} alt="..." />
            </div>
          </Slider>
        </div>
        <div className="search-home my-container">
          <div className="search-header">
            <h2>
              Find the <span>right home</span> and <span>customize</span> to fit
              your needs
            </h2>
            <p>
              We Help you find the best places and offer near you. Bring to the
              table win-win survival strategies to ensure proactive domination
              going forward.
            </p>
            <span className="search-title--content">
              &ldquo;Explore full-service customized short-term rental&rdquo;
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HeroBannerSec;
