// import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

// const CustomPagination = ({
//   totalItems,
//   itemsPerPage,
//   currentPage,
//   onPageChange,
//   disabledPage,
// }) => {
//   const totalPages = Math.ceil(totalItems / itemsPerPage);
//   if (totalPages === 1) {
//     // If there is only one page, return null or an empty component
//     return null;
//   }

//   const renderPaginationItems = () => {
//     const paginationItems = [];

//     const renderPageLink = (page) => (
//       <PaginationItem
//         key={page}
//         className={currentPage === page ? "active" : ""}
//       >
//         <PaginationLink
//           href="#"
//           onClick={(e) => {
//             e.preventDefault();
//             onPageChange(page);
//           }}
//         >
//           {page}
//         </PaginationLink>
//       </PaginationItem>
//     );

//     const renderDots = (key) => (
//       <PaginationItem key={key} disabled>
//         <PaginationLink href="#">...</PaginationLink>
//       </PaginationItem>
//     );

//     // Previous button
//     paginationItems.push(
//       <PaginationItem
//         key="prev"
//         className={currentPage === 1 ? "disabled" : ""}
//       >
//         <PaginationLink
//           href="#"
//           onClick={(e) => {
//             e.preventDefault();
//             onPageChange(currentPage - 1);
//           }}
//         >
//           <i className="fas fa-angle-left" />
//           <span className="sr-only">Previous</span>
//         </PaginationLink>
//       </PaginationItem>
//     );

//     // First page
//     paginationItems.push(renderPageLink(1));

//     // Dots or second page if needed
//     if (currentPage > 4) {
//       paginationItems.push(renderDots("dots1"));
//     } else if (currentPage === 4) {
//       paginationItems.push(renderPageLink(2));
//     }

//     // Pages around the current page
//     for (
//       let i = Math.max(2, currentPage - 1);
//       i <= Math.min(totalPages - 1, currentPage + 1);
//       i++
//     ) {
//       paginationItems.push(renderPageLink(i));
//     }

//     // Dots or second-to-last page if needed
//     if (currentPage < totalPages - 2) {
//       paginationItems.push(renderDots("dots2"));
//     } else if (currentPage === totalPages - 2) {
//       paginationItems.push(renderPageLink(totalPages - 1));
//     }

//     // Last page
//     paginationItems.push(renderPageLink(totalPages));

//     // Next button
//     paginationItems.push(
//       <PaginationItem
//         key="next"
//         className={currentPage === totalPages ? "disabled" : ""}
//       >
//         <PaginationLink
//           href="#"
//           onClick={(e) => {
//             e.preventDefault();
//             onPageChange(currentPage + 1);
//           }}
//         >
//           <i className="fas fa-angle-right" />
//           <span className="sr-only">Next</span>
//         </PaginationLink>
//       </PaginationItem>
//     );

//     return paginationItems;
//   };
//   return (
//     <Pagination
//       className="pagination justify-content-end mb-0"
//       listClassName="justify-content-end mb-0"
//       disabled={disabledPage}
//     >
//       {renderPaginationItems()}
//     </Pagination>
//   );
// };

// export default CustomPagination;

import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const CustomPagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  disabledPage,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // If there's only one page, return null
  if (totalPages === 1) {
    return null;
  }

  // Render pagination items (pages, dots, prev/next)
  const renderPaginationItems = () => {
    const paginationItems = [];

    const renderPageLink = (page) => (
      <PaginationItem
        key={page}
        className={currentPage === page ? "active" : ""}
      >
        <PaginationLink
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onPageChange(page);
          }}
        >
          {page}
        </PaginationLink>
      </PaginationItem>
    );

    const renderDots = (key) => (
      <PaginationItem key={key} disabled>
        <PaginationLink href="#">...</PaginationLink>
      </PaginationItem>
    );

    // Previous button
    paginationItems.push(
      <PaginationItem
        key="prev"
        className={currentPage === 1 || disabledPage ? "disabled" : ""}
      >
        <PaginationLink
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (currentPage > 1 && !disabledPage) onPageChange(currentPage - 1);
          }}
        >
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
    );

    // First page
    paginationItems.push(renderPageLink(1));

    // Dots before current page if necessary
    if (currentPage >= 4) {
      paginationItems.push(renderDots("dots1"));
    }

    // Pages around the current page
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 1, currentPage + 1);
      i++
    ) {
      paginationItems.push(renderPageLink(i));
    }

    // Dots after current page if necessary
    if (currentPage < totalPages - 2) {
      paginationItems.push(renderDots("dots2"));
    }

    // Last page
    if (totalPages > 1) paginationItems.push(renderPageLink(totalPages));

    // Next button
    paginationItems.push(
      <PaginationItem
        key="next"
        className={currentPage === totalPages || disabledPage ? "disabled" : ""}
      >
        <PaginationLink
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (currentPage < totalPages && !disabledPage)
              onPageChange(currentPage + 1);
          }}
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    );

    return paginationItems;
  };

  return (
    <Pagination
      className="pagination justify-content-center mb-0"
      listClassName="justify-content-end mb-0"
    >
      {renderPaginationItems()}
    </Pagination>
  );
};

export default CustomPagination;

