const PropertyTypeFilter = ({ queryParams, handleChange }) => {
  // Property types available
  const propertyTypes = [
    { value: "house", label: "House" },
    { value: "apartment", label: "Apartments" },
    { value: "office", label: "Office" },
    { value: "villa", label: "Villa" },
  ];

  // Parse house types from query params
  const getCurrentHouseTypes = () => {
    // If house_type is a string, split it by comma
    return queryParams.house_type
      ? queryParams.house_type.split(",").filter((type) => type)
      : [];
  };

  // Check if all property types are selected
  const isAllSelected = () => {
    const currentTypes = getCurrentHouseTypes();
    return (
      currentTypes.length === 0 || currentTypes.length === propertyTypes.length
    );
  };

  // Handle property type selection
  const handlePropertyTypeChange = (type) => {
    const currentTypes = getCurrentHouseTypes();

    let newTypes;
    if (currentTypes.includes(type)) {
      // Remove type if already selected
      newTypes = currentTypes.filter((t) => t !== type);
    } else {
      // Add type if not selected
      newTypes = [...currentTypes, type];
    }

    // Convert to comma-separated string or empty string
    const houseTypeValue = newTypes.length > 0 ? newTypes.join(",") : "";

    // Update query params
    handleChange("house_type", houseTypeValue);
  };

  // Toggle all property types
  const handleAllTypesToggle = () => {
    const currentTypes = getCurrentHouseTypes();

    // If all are selected or some are selected, clear selection
    // Otherwise, select all types
    const newHouseTypeValue = isAllSelected()
      ? ""
      : propertyTypes.map((type) => type.value).join(",");

    handleChange("house_type", newHouseTypeValue);
  };

  return (
    <div className="listing-check">
      <h4>Property Type</h4>

      {/* All Types Checkbox */}
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="customCheckAllTypes"
          checked={isAllSelected()}
          onChange={handleAllTypesToggle}
        />
        <label className="custom-control-label" htmlFor="customCheckAllTypes">
          All
        </label>
      </div>

      {/* Individual Property Type Checkboxes */}
      {propertyTypes.map(({ value, label }) => {
        const currentTypes = getCurrentHouseTypes();
        const isChecked = currentTypes.includes(value) || isAllSelected();

        return (
          <div key={value} className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customCheck${value}`}
              checked={isChecked}
              onChange={() => handlePropertyTypeChange(value)}
            />
            <label
              className="custom-control-label"
              htmlFor={`customCheck${value}`}
            >
              {label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default PropertyTypeFilter;
