import React, { Fragment, useEffect, useState } from "react";

import { Modal, Button } from "react-bootstrap";
import colorNamer from "color-namer";
import ProfileImg from "../../../assets/images/profile.png";
import Account2Img from "../../../assets/images/account2.png";
import CallImg from "../../../assets/images/call.png";
import MessageImg from "../../../assets/images/message.png";
import LocationPinImg from "../../../assets/images/location_pin.png";
import DeluxingImg from "../../../assets/images/deluximg.png";
import BookOrderStatusImg from "../../../assets/images/bookorderstatus.png";
import TopHeadArrowImg from "../../../assets/images/topheadarrow.png";
import BookItem1Img from "../../../assets/images/bookitem1.png";
import BookItem2Img from "../../../assets/images/bookitem2.png";
import BookItem3Img from "../../../assets/images/bookitem3.png";
import BookItem4Img from "../../../assets/images/bookitem4.png";
import BookItem5Img from "../../../assets/images/bookitem5.png";
import BookItem6Img from "../../../assets/images/bookitem6.png";
import houseDummy from "../../../assets/images/houseDummy.webp";
import { useUserState, useUserDispatch } from "../../../Contexts/UserContext";
import axios from "axios";
const MyBookingDetails = () => {
  const { user, isLoggedIn } = useUserState();
  const [myBookingDetail, setMyBookingDetail] = useState([]);
  const [deliveredModal, setDeliveredModal] = useState(false);
  const [itemId, setItemId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };

  useEffect(() => {
    if (user?.token) {
      getCurrentPlan();
    }
  }, [user?.token]);
  const headers = {
    Authorization: user?.token,
  };
  const getCurrentPlan = async () => {
    setIsLoading(true);
    const res = await axios.get(
      `${
        import.meta.env.VITE_APP_BASE_URL
      }/api/transactions/get-by-user-furniture-owner`,
      {
        headers: headers,
      }
    );
    if (res) {
      const updatedData = res?.data.data.map((item) => {
        return {
          ...item,
          furniture_category_name:
            item?.furnitureCarts[0]?.furniture_category_data.name,
        };
      });
      setMyBookingDetail(updatedData);
      setIsLoading(false);
    } else {
      alert("error");
      setIsLoading(false);
    }
  };

  const imgPath = import.meta.env.VITE_APP_BASE_URL;

  const handleConfirmedDelivery = async () => {
    setDeliveredModal(true);
    try {
      const apiRes = await axios.put(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/cart/update-furniture-delivery-status`,
        {
          cartItemId: itemId,
          deliveryStatus: "delivered",
        },
        {
          
          headers: headerJson,
        }
      );
      setDeliveredModal(false);
      getCurrentPlan();
    } catch (err) {
      console.log(err);
    }
  };


  const currentDate = new Date();
 
 let  current_date = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
   const handleError = (e) => {
     e.target.src = houseDummy; // Fallback to the dummy image on error
   };
  return (
    <Fragment>
      <div className="main-box">
        <div className="content-header">
          <h3>My Booking Details</h3>
        </div>
        {isLoading ? (
          <div className="eye-loader-frame m-5 p-5">
            <div className="eye-loader-spinner"></div>
          </div>
        ) : (
          <>
            {myBookingDetail &&
              myBookingDetail?.map((ele) => {
                const dateObject = ele?.lease_start_date
                  ? new Date(ele.lease_start_date)
                  : null;
                let leas_start_date = "";
                if (dateObject) {
                  leas_start_date = dateObject.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                }
                const dateObject1 = ele?.lease_end_date
                  ? new Date(ele?.lease_end_date)
                  : null;
                let leas_end_date = "";
                if (dateObject1) {
                  leas_end_date = dateObject1.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                }
                const dateObject2 = ele?.furn_delivery_date
                  ? new Date(ele?.furn_delivery_date)
                  : null;
                let furn_delivery_date = "";
                if (dateObject2) {
                  furn_delivery_date = dateObject2.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                }

                return (
                  <>
                    <div className="furnit_booking_cont">
                      <div className="furnit_book_profile_cont">
                        <div className="profile-sec">
                          <img src={ProfileImg} />
                        </div>
                        <ul className="furnit_book_inner_cont">
                          <li>
                            <img src={Account2Img} alt="account2" />
                            <p>
                              {ele?.user?.first_name} {ele?.user?.last_name}
                            </p>
                          </li>
                          <li>
                            <img src={CallImg} alt="call" />
                            <p>{ele?.user?.phone_number}</p>
                          </li>
                          <li>
                            <img src={MessageImg} alt="message" />
                            <p>{ele?.user?.email}</p>
                          </li>
                          <li>
                            <img src={LocationPinImg} alt="message" />
                            <p>
                              {ele?.house_data?.full_address.replaceAll(
                                ";",
                                ","
                              )}
                              ,{ele?.house_data?.state},
                              {ele?.house_data?.country},
                              {ele?.house_data?.zip_code}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <hr />
                      <div className="furnit_delux">
                        <div className="delux_img">
                          <img src={DeluxingImg} alt="delux_img" />
                        </div>
                        <div className="furnit_delux_cont">
                          <h4>{ele?.furniture_category_name}</h4>
                          <h4>Starting Date : {furn_delivery_date}</h4>
                          <div className="furnit_order_status">
                            <h4>Delivery Status :</h4>
                            <ul
                              className={
                                ele.furn_delivery_status === "delivered"
                                  ? "furnit_track_order"
                                  : ""
                              }
                            >
                              <li>
                                <img
                                  src={BookOrderStatusImg}
                                  alt="bookorderstatus"
                                />{" "}
                                Order Confirmed,{leas_start_date}
                              </li>
                              {ele?.furn_delivery_status === "delivered" && (
                                <li>
                                  <img
                                    src={BookOrderStatusImg}
                                    alt="bookorderstatus"
                                  />{" "}
                                  Delivered, {furn_delivery_date}
                                </li>
                              )}
                            </ul>
                          </div>

                          <div>
                            {/* {
                      dateObject ==
                      } */}
                            {ele?.furn_delivery_status === "delivered" ? (
                              ""
                            ) : (
                              <button
                                disabled={`${
                                  new Date() >=
                                    new Date(ele?.lease_start_date) &&
                                  new Date() <=
                                    new Date(ele?.furn_delivery_date)
                                }`}
                                style={
                                  `${
                                    new Date() >=
                                      new Date(ele?.lease_start_date) &&
                                    new Date() <=
                                      new Date(ele?.furn_delivery_date)
                                  }`
                                    ? {
                                        backgroundColor: "grey",
                                        border: "none",
                                      }
                                    : {}
                                }
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  setDeliveredModal(true);
                                  setItemId(ele?.id);
                                }}
                              >
                                Delivered
                              </button>
                            )}
                          </div>
                          <h2>
                            View Delux Furniture Items{" "}
                            {/* <img src={TopHeadArrowImg} alt="" /> */}
                          </h2>
                          <div className="furnit_item_detail">
                            {ele?.furnitureCarts?.map((ele, i) => {
                              const color = ele?.furniture_data?.color || "";
                              let colorName;
                              const isValidColor = /^#[0-9A-F]{6}$/i.test(
                                color
                              );

                              if (isValidColor) {
                                const colorNameObject =
                                  colorNamer(color)?.ntc?.[0];
                                colorName = colorNameObject?.name || "";
                              }
                              return (
                                <div key={i} className="delux_furnit_item">
                                  <div className="delux_furnit_item_inner">
                                    <div className="delux_item">
                                      <div className="book_item_img">
                                        <img
                                          src={`${imgPath}${ele.furniture_data?.furniture_images[0]?.imageUrl}`}
                                          alt=""
                                        />
                                      </div>
                                      <div className="zunkel_cont">
                                        <h4>{ele?.furniture_data?.name}</h4>
                                        <p>
                                          <span className="br_item">
                                            {ele?.quantity} item
                                          </span>{" "}
                                          <div
                                            style={{
                                              backgroundColor:
                                                `${color}` || "#000",
                                              width: "20px",
                                              height: "20px",
                                              borderRadius: "2px",
                                            }}
                                          ></div>
                                          <span>
                                            {colorName ? colorName : "black"}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h4>House Detail</h4>
                    <div className="hist_main">
                      <div className="hist_img">
                        <img
                          src={`${imgPath}${ele?.house_data.houseImages[0]?.imageUrl} `}
                          alt="histhomeimg"
                          onError={handleError}
                        />
                      </div>
                      <div className="hist_cont">
                        <h>{ele?.house_data.name}</h>
                        <p>
                          {ele?.house_data?.full_address.replaceAll(";", ",")} ,
                          {ele?.house_data?.state} ,{ele?.house_data?.country} ,
                          {ele?.house_data?.zip_code}
                        </p>
                        <div className="month_cont">
                          <h2>Starting Date : {leas_start_date}</h2>
                          <h2>End Date : {leas_end_date}</h2>
                        </div>
                        {/* <div className="row ">
                      <div className="col-md-4 ">
                      <p><b>House type:</b> {ele?.house_data?.house_type}</p>
                      </div>
                      <div className="col-md-4 ">
                      <p><b>House facility:</b> {ele?.house_data?.house_facility}</p>
                      </div>
                      <div className="col-md-4 ">
                      <p><b>Floor:</b> {ele?.house_data?.floor}</p>
                      </div>
                    </div>
                      <div className="row">
                      <div className="col-md-4 ">
                      <p><b>Facing side:</b> {ele?.house_data?.facing_side}</p>
                      </div>
                      <div className="col-md-4 ">
                      <p><b>Area:</b> {ele?.house_data?.area}</p>
                      </div>
                      <div className="col-md-4 ">
                      <p><b>Bed rooms:</b> {ele?.house_data?.bedroom_no}</p>
                      </div>
                      </div>

                      <div className="row">
                      <div className="col-md-4 ">
                      <p><b>Bath rooms:</b> {ele?.house_data?.bathroom_no}</p>
                      </div>
                      <div className="col-md-4 ">
                      <p><b>Month price:</b> {ele?.house_data?.monthly_price}</p>
                      </div>
                      <div className="col-md-4 ">
                      <p><b>Security price:</b> {ele?.house_data?.security_prices}</p>
                      </div>
                    </div>
                    <div className="row">
                    <p><b>Property detail:</b></p>
                      <p>{ele?.house_data?.property_details}</p>
                    </div> */}
                        {/* <div className="month_cont">
                      <h2>Area: {ele?.house_data?.area}</h2>
                      <h2>Property detail: {ele?.house_data?.property_details}</h2>
                    </div> */}
                        {/* <div className="month_cont">
                      <h2>Bed rooms: {ele?.house_data?.bedroom_no}</h2>
                      <h2>Bath rooms: {ele?.house_data?.bathroom_no}</h2>
                    </div>
                    <div className="month_cont">
                      <h2>Month price: {ele?.house_data?.monthly_price}</h2>
                      <h2>Security price: {ele?.house_data?.security_prices}</h2>
                    </div> */}
                      </div>
                    </div>
                    <hr className="m-0" />
                  </>
                );
              })}
          </>
        )}
      </div>

      <Modal
        show={deliveredModal}
        onHide={() => setDeliveredModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmed Delivery</Modal.Title>
        </Modal.Header>
        <Modal.Body>Press yes to confirm the furniture delivery</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeliveredModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleConfirmedDelivery()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default MyBookingDetails;
