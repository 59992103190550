import { useNavigate } from "react-router-dom";
import { useUserState } from "../../Contexts/UserContext";
import { useEffect } from "react";

export const ProtectedRoute = ({ children }) => {
  const { user } = useUserState();
  const navigate = useNavigate();
  useEffect(() => {
    if (user == undefined) {
      return navigate("/login");
    }
  }),
    [user];
  return children;
};
