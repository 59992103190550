import profileImage from "@images/profile.png";
import account2Image from "@images/account2.png";
import callImage from "@images/call.png";
import messageImage from "@images/message.png";
import locationPinImage from "@images/location pin.png";
import cleaningimgImage from "@images/cleaningimg.png";
import bookboyImage from "@images/bookboy.png";
import booktwogirlImage from "@images/booktwogirl.png";
import bookgirlImage from "@images/bookgirl.png";
import topheadarrowImage from "@images/topheadarrow.png";
import { useUserState } from "../../../Contexts/UserContext";
import { Container, Row, Col, Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import histhomeimg from "../../../assets/images/houseDummy.webp";

const MyCleaningBooking = () => {
  const { user, isLoggedIn } = useUserState();
  const [myCleaningsData, setMyCleaningsData] = useState();
  const [selectedService, setSelectedService] = useState(null);
  const [seletedCategoryId, setSeletedCategoryId] = useState(null);
  const [ButtonDisable, setButtonDisable] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);

  const headers = {
    Authorization: user?.token,
    "Content-Type": "application/json",
  };
  const DateConverter = (dates) => {
    return dates.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const FetchData = async (url, method, data = null) => {
    const baseCartUrl = `${import.meta.env.VITE_APP_BASE_URL}${url}`;

    try {
      let response;
      if (method === "get") {
        response = await axios.get(baseCartUrl, { headers });
      } else if (method === "put") {
        response = await axios.put(baseCartUrl, data, { headers });
      } else {
        throw new Error("Unsupported HTTP method");
      }
      if (response.status === 200) {
        return response?.data?.data;
      }
      // Consider handling other status codes if needed.
    } catch (error) {
      console.error("Error during API request:", error);
      throw error; // Re-throw the error to propagate it up the call stack.
    }
  };

  const optimizeDataTransformation = (data) => {
    const result = data.map(
      ({
        cleaningServiceCarts,
        user,
        house_cleaned,
        lease_start_date,
        lease_end_date,
        house_data,
      }) => {
        const cleaningServiceDataMap = new Map();

        cleaningServiceCarts.forEach((ele) => {
          const categoryId = ele.cleaning_service_category_id;
          const cleaningServiceCartsId = [];
          const frequency = ele.frequency;
          const cleaning_cart = ele.cleaning_cart.map((cleaningItem) => ({
            id: cleaningItem.id,
            house_cleaned: cleaningItem.house_cleaned,
            selected_date: cleaningItem.selected_date,
            completed_at: cleaningItem.completed_at,
          }));
          cleaningServiceCartsId.push({
            id: ele.id,
            frequency,
            completed: ele.cleaning_cart.filter(
              (d) => d.house_cleaned === "completed"
            ).length,
          });
          const completedService = cleaningServiceCartsId
            .map(({ completed }) => completed)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );

          if (cleaningServiceDataMap.has(categoryId)) {
            // If category ID already exists, add the frequency and cleaning cart
            const existingData = cleaningServiceDataMap.get(categoryId);
            existingData.frequency += frequency;
            existingData.completedService += completedService;
            existingData.cleaningServiceCartsId.push(...cleaningServiceCartsId);
            existingData.cleaning_cart.push(...cleaning_cart);
          } else {
            // If category ID is not present, set the frequency and cleaning cart
            cleaningServiceDataMap.set(categoryId, {
              frequency,
              completedService,
              cleaningServiceCartsId,
              cleaning_cart,
            });
          }
        });

        const main_cart_id = cleaningServiceCarts[0].main_cart_id;
        const cleaningServiceData = Array.from(
          cleaningServiceDataMap.entries()
        ).map(([categoryId, data]) => ({
          cleaning_service_category_id: categoryId,
          frequency: data.frequency,
          remainingService: data.frequency - data.completedService,
          cleaningServiceCartsId: data.cleaningServiceCartsId,
          cleaning_cart: data.cleaning_cart,
          cleaning_category_name: cleaningServiceCarts.find(
            (ele) => ele.cleaning_service_category_id === categoryId
          ).cleaning_category_data.name,
        }));

        return {
          cleaningServiceData,
          house_cleaned,
          main_cart_id,
          lease_start_date,
          lease_end_date,
          house_detail: {
            name: house_data.name,
            full_address: `${house_data.full_address}, ${house_data.state}, ${house_data.country}-${house_data.zip_code}`,
          },
          user: {
            user_name: `${user.first_name} ${user.last_name}`,
            phone_number: user.phone_number,
            email: user.email,
            user_image: user.profile_img,
          },
        };
      }
    );

    return result;
  };

  const ApiCallFunction = async () => {
    setLoader(true);
    let data = await FetchData(
      "/api/transactions/get-by-user-cleaning-owner",
      "get"
    );
    setMyCleaningsData(optimizeDataTransformation(data));
    setLoader(false);
  };

  const ApiUpdateHoseClean = async (id) => {
    await FetchData(
      `/api/cleaning-service-schedule/update-by-cleaning-cart/${id}`,
      "put",
      {
        house_cleaning: "completed",
      }
    );
    await ApiCallFunction();
  };

  useEffect(() => {
    if (!isLoader) ApiCallFunction();
  }, [user?.token]);

  const handleSelect = (eventKey, event) => {
    setSelectedService(eventKey);
    setSeletedCategoryId(event.target.attributes.id.value);
  };

  const handleClose = () => {
    setModalData(null), setShow(false);
  };

  const handleButtonActive = (data) => {
    setModalData(data);
    setShow(true);
    // if (house_cleaned !== "completed") {
    //   ApiUpdateHoseClean(main_cart_id);
    // }
  };
  const onClickSubmitButton = async () => {
    let selectItem =
      seletedCategoryId &&
      JSON.parse(seletedCategoryId).find((item) => {
        return item.frequency > item.completed;
      });

    if (selectItem && Object.keys(selectItem).length > 0) {
      try {
        // API call when selectItem exists and is not empty
        await ApiUpdateHoseClean(selectItem.id);
        // Additional logic after API call if needed

        // Resetting values and state
        setSeletedCategoryId(null);
        setSelectedService(null);
        setShow(false);
      } catch (error) {
        // Handle error if needed
      }
    } else {
      // Setting values and state when selectItem is null or empty
      setButtonDisable(false);
      setSeletedCategoryId(null);
      setSelectedService(null);
      setShow(false);
    }
  };
  useEffect(() => {
    if (!show) {
      setSeletedCategoryId(null);
      setSelectedService(null);
    }
  }, [show]);

  return (
    <div>
      <div className="main-box">
        <div className="content-header">
          <h3>My Booking Details</h3>
        </div>
        {isLoader ? (
          <div className="eye-loader-frame m-5 p-5">
            <div className="eye-loader-spinner"></div>
          </div>
        ) : (
          <>
            {myCleaningsData && myCleaningsData.length > 0 ? (
              myCleaningsData.map(
                (
                  {
                    cleaningServiceData,
                    house_cleaned,
                    delivery_date,
                    cleaning_type,
                    lease_end_date,
                    lease_start_date,
                    house_detail,
                    user,
                    main_cart_id,
                  },
                  index
                ) => {
                  return (
                    <div key={index}>
                      <div className="furnit_booking_cont ">
                        <h1 style={{ fontSize: "2rem" }}>Tenant Detail :</h1>
                        <br />
                        <div className="furnit_book_profile_cont">
                          <div className="profile-sec">
                            <img
                              src={user.user_image ?? profileImage}
                              onError={(e) => {
                                e.target.src = profileImage;
                              }}
                              alt="profile"
                            />
                          </div>
                          <ul className="furnit_book_inner_cont">
                            <li>
                              <img src={account2Image} alt="account2" />
                              <p>{user?.user_name}</p>
                            </li>
                            <li>
                              <img src={callImage} alt="call" />
                              <p>{user?.phone_number}</p>
                            </li>
                            <li>
                              <img src={messageImage} alt="message" />
                              <p>{user?.email}</p>
                            </li>
                          </ul>
                        </div>
                        <hr />
                        <br></br>
                        <h1 style={{ fontSize: "2rem" }}>House Detail :</h1>
                        <div className="hist_main">
                          <div className="hist_img">
                            <img
                              src={
                                house_detail.house_data?.houseImages.length > 0
                                  ? `${import.meta.env.VITE_APP_BASE_URL}${
                                      house_detail.house_data?.houseImages[0]
                                        ?.imageUrl
                                    }`
                                  : histhomeimg
                              }
                              alt="histhomeimg"
                            />
                          </div>
                          <div className="hist_cont">
                            <h>{house_detail?.name}</h>
                            <p>{house_detail?.full_address}</p>
                            <div className="month_cont">
                              <h2>
                                Starting Date :{" "}
                                <span>
                                  {DateConverter(new Date(lease_start_date))}
                                </span>
                              </h2>
                              <h2>
                                End Date :{" "}
                                <span>
                                  {DateConverter(new Date(lease_end_date))}
                                </span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h1 style={{ fontSize: "2rem" }}>
                            Cleaning Detail :
                          </h1>

                          <button
                            className="btn btn-primary"
                            disabled={
                              cleaningServiceData.every(
                                (clean) => clean.remainingService === 0
                              )
                                ? true
                                : false
                            }
                            onClick={() => {
                              handleButtonActive(cleaningServiceData);
                            }}
                          >
                            {cleaningServiceData.every(
                              (clean) => clean.remainingService === 0
                            )
                              ? "completed"
                              : "Service completed"}
                          </button>
                        </div>
                        <br />
                        <div className="furnit_delux">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            className="delux_img"
                          >
                            <img src={cleaningimgImage} alt="delux_img" />
                          </div>
                          <div className="hist_main">
                            <div>
                              {cleaningServiceData &&
                                cleaningServiceData.map(
                                  ({
                                    cleaning_service_category_id,
                                    cleaning_category_name,
                                    remainingService,
                                  }) => (
                                    <div
                                      style={{ marginBottom: "20px" }}
                                      key={cleaning_service_category_id}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          minWidth: "20rem",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span>{cleaning_category_name} :</span>
                                        <span>
                                          {remainingService} (Remaining)
                                        </span>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                            <div>
                              <div className="hist_main d-inline-block ">
                                <hr />
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                        <hr
                          style={{
                            border: "none",
                            height: "5px",
                            backgroundColor: "#333",
                          }}
                        />
                      </div>
                      <div>
                        <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Cleaning Service Completed
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <p style={{ marginBottom: "15px" }}>
                              Select cleaning service type:
                            </p>
                            <Dropdown onSelect={handleSelect}>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                style={{
                                  backgroundColor: "#0474ba",
                                }}
                              >
                                {selectedService
                                  ? selectedService
                                  : "Choose service"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="text-center">
                                {modalData && modalData.length > 0 ? (
                                  modalData
                                    .filter(
                                      ({ cleaning_category_name }) =>
                                        cleaning_category_name !==
                                        selectedService
                                    )
                                    .map(
                                      ({
                                        cleaning_service_category_id,
                                        cleaning_category_name,
                                        remainingService,
                                        cleaningServiceCartsId,
                                      }) => (
                                        <Dropdown.Item
                                          key={cleaning_service_category_id}
                                          eventKey={cleaning_category_name}
                                          value={cleaning_service_category_id}
                                          id={JSON.stringify(
                                            cleaningServiceCartsId
                                          )}
                                          disabled={
                                            remainingService === 0
                                              ? true
                                              : false
                                          }
                                        >
                                          {cleaning_category_name} (
                                          {remainingService})
                                        </Dropdown.Item>
                                      )
                                    )
                                ) : (
                                  <Dropdown.Item href="#/action-3">
                                    Service Completed
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Modal.Body>
                          <Modal.Footer>
                            {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button> */}
                            <Button
                              style={{
                                backgroundColor: "#0474ba",
                              }}
                              onClick={() => {
                                onClickSubmitButton();
                              }}
                            >
                              Submit
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <div>There are no booking </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MyCleaningBooking;
